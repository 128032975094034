import $ from 'jquery';

// sets HTML5 errors for a given field on a given form
export function setFieldError(formId, fieldId, error) {
  // escape special characters in the ID
  fieldId = fieldId.replace('[', '\\[').replace(']', '\\]').replace('.', '\\.');

  // if we have a form ID, look for the form first
  var selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0] && error) {
    // ... set it
    if (field[0]) {
      field[0].setCustomValidity(error);
    }
  }
}

// clears HTML5 errors for a given field
export function clearFieldError(formId, fieldId) {
  // escape special characters in the ID
  fieldId = fieldId.replace('[', '\\[').replace(']', '\\]').replace('.', '\\.');

  // if we have a form ID, look for the form first
  var selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0]) {
    // ... clear it
    if (field[0]) {
      field[0].setCustomValidity(``);
    }
  }
}

// tries to detect if a user's device is touch-enabled; this is not
// perfect, so it should be used for mission critical decisions
export const isTouchDevice = () => {
  var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  var mq = function (query) {
    return window.matchMedia(query).matches;
  };

  if (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        (window.DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof window.DocumentTouch))
    ) ||
    !!(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator.msMaxTouchPoints))
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
};
