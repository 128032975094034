import PropTypes from 'prop-types';
import 'react-confirm-alert/src/react-confirm-alert.css';
import requiredIf from 'react-required-if';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* Confirm dialog. */
class ConfirmDialog extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="container-fluid png-confirm-dialog">
        <div className="row">
          <div className="col">
            <h2>{this.props.prompt}</h2>
          </div>
        </div>
        {this.props.question && (
          <div className="row">
            <div className="col">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.props.question,
                }}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col text-center">
            {this.props.buttons ? (
              this.props.buttons
            ) : (
              <>
                <div className="form-row">
                  <div className="form-group col text-left">
                    <button
                      type="button"
                      onClick={() => {
                        // close the dialog
                        this.props.onClose();
                      }}
                      title={this.props.noTitle ? this.props.noTitle : this.props.noLabel}
                      className="btn btn-primary btn-lg"
                    >
                      {this.props.noLabel}
                    </button>
                  </div>
                  <div className="form-group col text-right">
                    <button
                      type="button"
                      onClick={() => {
                        // close the dialog
                        this.props.onClose();

                        // perform the logic
                        return this.props.onConfirm();
                      }}
                      title={this.props.yesTitle ? this.props.yesTitle : this.props.yesLabel}
                      className="btn btn-primary btn-lg"
                    >
                      {this.props.yesLabel}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// set prop types and required-ness
ConfirmDialog.propTypes = {
  prompt: PropTypes.string.isRequired,
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: requiredIf(PropTypes.func, (props) => !props.buttons),
  noLabel: requiredIf(PropTypes.string, (props) => !props.buttons),
  noTitle: PropTypes.string,
  onConfirm: requiredIf(PropTypes.func, (props) => !props.buttons),
  yesLabel: requiredIf(PropTypes.string, (props) => !props.buttons),
  yesTitle: PropTypes.string,
  buttons: PropTypes.array,
};

// set default props
ConfirmDialog.defaultProps = {};

export default ConfirmDialog;
