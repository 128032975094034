import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BasePage from 'components/Page';
import Retry from 'components/Retry';
import Loading from 'components/Loading';
import { fetch as getVersion } from 'entities/Version/actions';

/* Info page. */
class Info extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for version management
    this.state = {
      ...this.state,
      versionFetchError: false,
      versionLoading: false,
      version: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // load version
    if (!this.state.versionLoading) {
      this.loadVersion();
    }
  }

  loadVersion() {
    this.setState({ versionLoading: true });
    this.props
      .loadVersion()
      .then((version) => {
        this.setState({ version: version });
        this.setState({ versionFetchError: false });
      })
      .catch((e) => {
        console.error('Error loading version', e);
        this.setState({ versionFetchError: true });
      })
      .finally(() => this.setState({ versionLoading: false }));
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col png-page-header">App Info</div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Version</h3>
          </div>
        </div>

        {/* version */}
        <section id="version">
          {/* error? */}
          {this.state.versionFetchError && (
            <Retry
              onRefresh={() => {
                this.loadVersion();
              }}
            />
          )}

          {/* still loading? */}
          {this.state.versionLoading && !this.state.versionFetchError && (
            <div className="row">
              <div className="col-12 text-center png-content-loading">
                <Loading />
              </div>
            </div>
          )}

          {/* version */}
          {!this.state.versionFetchError && this.state.version && (
            <div>
              <div className="row">
                <div className="col">Web Application Version: {process.env.REACT_APP_VERSION}</div>
              </div>
              <div className="row">
                <div className="col">Hub Version: {this.state.version.hub}</div>
              </div>
              <div className="row">
                <div className="col">Database Version: {this.state.version.database}</div>
              </div>
            </div>
          )}
        </section>
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // loads the version
  loadVersion: () => {
    // get the version
    return dispatch(getVersion());
  },
});

// turn this into a container component
export default withRouter(connect(null, mapDispatchToProps)(Info));
