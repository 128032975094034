import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import Content from 'components/Content';
import FPCard from 'components/FPCard';
import { showDialog as showFPPModal } from 'components/FPModal/actions';
import './styles.scss';

/* A blurb about the FPP, including a summary of the logged in user's points. */
export class FPP extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      /* <Content name="homeFPP" showLoader={false} showError={false} /> */
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col png-page-header">Frequent Parker</div>
          </div>
          <div className="row">
            <div className="col">
              {!this.props.customer && (
                <p>You travel a lot, and you deserve to be rewarded for that.</p>
              )}
              <Content name="homeFPP" showLoader={false} showError={false} />
              {this.props.customer && (
                <p>Excellent work, savvy parker. You are logged in and ready to earn points.</p>
              )}
            </div>
          </div>
        </div>
        {!this.props.customer && (
          <div className="container-fluid">
            <div className="row">
              <div className="col text-center">
                <button
                  className="btn btn-lg btn-primary mt-3"
                  onClick={() => {
                    this.props.fppModal();
                  }}
                >
                  Login
                </button>
                &nbsp;&nbsp;&nbsp;
                <Link className="btn btn-lg btn-primary mt-3" to="/profile" role="button">
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
        )}
        {this.props.customer && (
          <div className="container-fluid">
            <div className="row justify-content-center">
              <FPCard customer={this.props.customer} />
            </div>
          </div>
        )}
        <div className="mt-5" />
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state) => ({
  // the existing customer, if there is one
  customer: state.context.customer,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // open FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FPP));
