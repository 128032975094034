import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  change,
  clearSubmitErrors,
  Field,
  Form,
  formValueSelector,
  reduxForm,
  submit,
} from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { enable as enableTestMode } from 'components/TestMode/actions';
import { fetch as getConfig } from 'entities/Config/actions';
import { getIdByNetParkCode } from 'entities/Facility/util';
import { storeOnContext } from 'util/Context/actions';
import { renderEnhancedField } from 'util/form/renderers';
import './styles.scss';

/* Frequent parker form. */
class FPForm extends BaseForm {
  submit = (values, dispatch) => {
    // special case
    if ('TEST'.localeCompare(values.fpNumber) === 0) {
      this.props.enableTest();
    } else {
      // translate the netPark code to a facility ID
      values.facilityId = getIdByNetParkCode(this.props.facilities, values.facilityCode);

      // make the correct call
      if (this.state.forgotFP) {
        return this.props.onForgotFP(values).then(() => {
          // if we got a customer, put his FP on the form
          this.setState({ forgotFP: false });
        });
      } else {
        return this.props.onSubmit(values).finally(() => {
          // clear OAuth2 values
          dispatch(change('fpForm', 'provider', null));
          dispatch(change('fpForm', 'token', null));
        });
      }
    }
  };

  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // forgotten FP number
    this.state = {
      ...this.state,
      forgotFP: false,
      showForgotFP: true,
    };

    // keep track of which OAuth2 types are supported
    this.state = {
      ...this.state,
      googleOAuth2Enabled: false,
      facebookOAuth2Enabled: false,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // is Google OAuth2 enabled?
    this.props.googleOAuth2Enabled().then((enabled) => {
      this.setState({ googleOAuth2Enabled: enabled });
    });

    // is Facebook OAuth2 enabled?
    this.props.facebookOAuth2Enabled().then((enabled) => {
      this.setState({ facebookOAuth2Enabled: enabled });
    });

    // when the modal is hidden, reset the forgot FP state
    $('#png-fp-login').on('hidden.bs.modal', () => {
      this.setState({ forgotFP: false });
    });
  }

  componentDidUpdate(prevProps) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps);

    // if we have an FP number, hide the forgot button
    this.setState({ showForgotFP: !this.props.fpNumber || this.props.fpNumber.length === 0 });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // get facilities
    const { facilities } = this.props;

    // render
    return (
      <div>
        <Form
          id={this.props.form}
          onSubmit={this.props.handleSubmit(this.submit)}
          className="png-fp-form"
          onChange={() => {
            // check HTML5 validity; this is necessary for user typing, and we do
            // it on a slight delay to account for dynamic fields that may appear
            checkValidity(this);
          }}
          onBlur={() => {
            // check HTML5 validity; this is necessary for browser auto-fills
            checkValidity(this);
          }}
        >
          {/* errors */}
          {this.props.error && (
            <div className="has-error">
              <div className="png-form-error">{this.props.error}</div>
            </div>
          )}

          {/* primary location */}
          <div className="form-row">
            <div className="form-group col has-error">
              <Field
                type="select"
                label="Primary Location"
                name="facilityCode"
                defaultValue=""
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Primary Location"
                tooltip={`Your primary location`}
                required={true}
                disabled={this.props.submitting}
                onChange={(e) =>
                  localStorage.setItem(
                    'selectedFacility',
                    getIdByNetParkCode(facilities, Number(e.target.value)),
                  )
                }
                normalize={(value) => Number(value)}
              >
                {facilities &&
                  facilities.length > 0 &&
                  this.renderFacilityOptions(facilities, false)}
              </Field>
            </div>
          </div>

          {/* email */}
          <div className="form-row">
            <div className="form-group col has-error">
              <Field
                type="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                labelClassName="col-form-label col-form-label-lg"
                className="form-control form-control-lg"
                component={renderEnhancedField}
                placeholder="Email Address"
                tooltip={`Your email address`}
                minLength={5}
                maxLength={128}
                required={true}
                disabled={this.props.submitting}
              />
            </div>
          </div>

          {/* FP # */}
          {!this.state.forgotFP && (
            <div className="form-row">
              <div className="form-group col has-error">
                <Field
                  type="text"
                  label="Frequent Parker #"
                  name="fpNumber"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="Frequent Parker #"
                  tooltip={`Your frequent parker number, starting with FP`}
                  pattern="([fF][pP][0-9]{6,7})|(TEST)"
                  normalize={(value) => value.toUpperCase()}
                  required={true}
                  disabled={this.props.submitting}
                />
                {this.state.showForgotFP && (
                  <div
                    className="png-login-forgot-fp png-inline-link"
                    onClick={() => this.setState({ forgotFP: true })}
                  >
                    Forgot?
                  </div>
                )}
              </div>
            </div>
          )}

          {/* retrieve FP button */}
          {this.state.forgotFP && (
            <div className="form-row">
              <div className="form-group col text-center">
                <button
                  type="submit"
                  disabled={
                    (!this.props.submitFailed &&
                      (this.props.invalid || !this.state.htmlValid || this.props.pristine)) ||
                    this.props.submitting
                  }
                  className="btn btn-primary btn-lg"
                >
                  Retrieve Number
                </button>
              </div>
            </div>
          )}

          {/* login button */}
          {!this.state.forgotFP && (
            <div className="form-row">
              <div className="form-group col text-center">
                <button
                  type="submit"
                  disabled={
                    (!this.props.submitFailed &&
                      (this.props.invalid || !this.state.htmlValid || this.props.pristine)) ||
                    this.props.submitting
                  }
                  className="btn btn-primary btn-lg"
                >
                  Login
                </button>
              </div>
            </div>
          )}
        </Form>

        {/* OAuth2 logins */}
        {!this.state.forgotFP &&
          (this.state.googleOAuth2Enabled || this.state.facebookOAuth2Enabled) && (
            <div className="container-fluid">
              {/* separator */}
              <div className="row">
                <div className="col text-center">
                  <hr width="40%" />
                </div>
              </div>

              <div className="row mb-3">
                {/* Google */}
                {this.state.googleOAuth2Enabled && (
                  <div className="png-oauth col text-center">
                    <GoogleLogin
                      className={'btn btn-primary btn-sm mt-0'}
                      clientId={process.env.REACT_APP_GOOGLE_OAUTH2_KEY}
                      onSuccess={(googleUser) => {
                        // we have a token, now pass that to the server to log the customer in
                        this.props.doOAuth2Login('google', googleUser.getAuthResponse().id_token);
                      }}
                      onFailure={(e) => {
                        if (!e.error || e.error !== 'popup_closed_by_user') {
                          if (e.error && e.error === 'idpiframe_initialization_failed') {
                            this.props.oAuth2CookieError(e);
                          } else {
                            console.error('Error signing into Google', e);
                            this.props.oAuth2Error(e);
                          }
                        }
                      }}
                      disabled={this.props.thirdPartyCookiesBlocked}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="btn btn-primary btn-sm mt-0 pl-0 pr-0"
                        >
                          <FontAwesomeIcon icon={['fab', 'google']} />
                          <span> Google</span>
                        </button>
                      )}
                    ></GoogleLogin>
                  </div>
                )}

                {/* Facebook */}
                {this.state.facebookOAuth2Enabled && (
                  <div className="png-oauth col text-center">
                    <FacebookLogin
                      version="3.1"
                      autoLoad={false}
                      appId={process.env.REACT_APP_FB_APP_ID}
                      redirectUri={`${window.location.origin}/profile`} // since login can happen on any page, we redirect to a well-known URI
                      responseType="token"
                      state="fbLogin"
                      scope="public_profile"
                      fields="name"
                      callback={(response) => {
                        // process the response
                        if (response && response.accessToken) {
                          // we have a token, now pass that to the server to log the customer in
                          this.props.doOAuth2Login('facebook', response.accessToken);
                        } else if (response && typeof response.status === 'undefined') {
                          // the user closed the window
                        } else {
                          console.error('Error signing into Facebook', response);
                          this.props.oAuth2Error();
                        }
                      }}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="btn btn-primary btn-sm mt-0 pl-0 pr-0"
                        >
                          <FontAwesomeIcon icon={['fab', 'facebook']} />
                          <span> Facebook</span>
                        </button>
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

// decorate with reduxForm()
FPForm = reduxForm({
  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(FPForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // set initial values
  initialValues: {
    facilityCode: ((facilityId) => {
      // make sure we recognize the facility
      const facility =
        facilityId && facilityId && ownProps.facilities.find((f) => f.id === Number(facilityId));
      return facility ? facility.netParkCode.toString() : null;
    })(localStorage.getItem('selectedFacility')),
  },

  // FP number
  fpNumber: formValueSelector(ownProps.form)(state, 'fpNumber'),

  // are third-party cookies blocked?
  thirdPartyCookiesBlocked: state.context.thirdPartyCookiesBlocked,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // enable test mode
  enableTest: () => {
    dispatch(enableTestMode());
  },

  // determines if Google OAuth2 is enabled
  googleOAuth2Enabled: () => {
    return dispatch(getConfig('web.oauth2.googleEnabled'))
      .then((config) => {
        return config && config.value.toLowerCase() === 'true';
      })
      .catch((e) => {
        console.error('Error determining if we should allow Google OAuth2', e);
        return false;
      });
  },

  // determines if Facebook OAuth2 is enabled
  facebookOAuth2Enabled: () => {
    return dispatch(getConfig('web.oauth2.facebookEnabled'))
      .then((config) => {
        return config && config.value.toLowerCase() === 'true';
      })
      .catch((e) => {
        console.error('Error determining if we should allow Facebook OAuth2', e);
        return false;
      });
  },

  // initiate OAuth2 login by submitting the form
  doOAuth2Login: (provider, token) => {
    // set the values on the form
    dispatch(change(this.props.form, 'provider', provider));
    dispatch(change(this.props.form, 'token', token));

    // submit the form
    dispatch(submit(this.props.form));
  },

  // error signing in via OAuth2
  oAuth2Error: () => {
    dispatch(
      addAlert(
        'error',
        'There was an error logging you in. Try again, or login using your Frequent Parker number.',
        5000,
      ),
    );
  },

  // third-party cookies required
  oAuth2CookieError: () => {
    // store this on the context so that we can disable things accordingly
    dispatch(storeOnContext('thirdPartyCookiesBlocked', true));
  },
});

// turn this into a container component
FPForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(FPForm));

// set default props
FPForm.defaultProps = {
  form: 'fpForm',
};

export default FPForm;
