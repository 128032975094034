// polyfills
import 'core-js';
import 'raf';
import 'url-search-params-polyfill';
import 'function.name';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'jquery/dist/jquery.js';

import ErrorBoundary from './ErrorBoundary';
import App from './App';
import createStore from './store';
import './styles.scss';

// for managing browser history via redux
const history = createBrowserHistory();

// create the store and associated persistor
const { store, persistor } = createStore(history);

// wrap a provider around the application
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </CookiesProvider>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
