/**
 * Normalizes a number (phone, zip) by removing all non-digits.
 */
export const normalizeNumber = (value) => {
  // if we don't have anything, return
  if (!value) {
    return value;
  }

  // strip all non-digits
  return value.replace(/\D/g, '');
};

/**
 * Formats a phone number by adding dashes where appropriate.
 */
export const formatPhone = (number) => {
  // if we don't have a number, return
  if (!number) {
    return '';
  }

  // strip US prefix
  number = number.replace('+1', '');

  // special case; user typed a dash
  const endingDash = number.endsWith('-');

  // strip non-digits
  number = number.replace(/\D/g, '');

  // if we don't have a number any longer, return
  if (!number) {
    return '';
  }

  // split into parts
  const splitter = /.{1,3}/g;

  // put the parts together with separators
  number = number.substring(0, 7).match(splitter).join('-') + number.substring(7);

  // handle ending dash
  if (endingDash && (number.length === 3 || number.length === 7)) {
    number += '-';
  }

  return number;
};

/**
 * Formats a zip code.
 */
export const formatZip = (number) => {
  // if we don't have a number, return
  if (!number) {
    return '';
  }

  // special case; user typed a dash
  const endingDash = number.endsWith('-');

  // force upper case
  number = number.toUpperCase();

  // US?
  if (/^[0-9]/i.test(number)) {
    // strip non-digits
    number = number.replace(/\D/g, '');

    // split into parts
    const splitter = /.{1,5}/g;

    // put the parts together with separators
    number = number.substring(0, 9).match(splitter).join('-') + number.substring(9);

    // handle ending dash
    if (endingDash && number.length === 5) {
      number += '-';
    }

    return number;
  } else if (/^[A-Z]/i.test(number)) {
    // Canada
    // strip the dash (if it's there)
    number = number.replace(/-/g, '');

    switch (number.length) {
      case 1:
        break;
      case 2:
        if (!/^[A-Z][0-9]/i.test(number)) {
          number = number.slice(0, -1);
        }
        break;
      case 3:
        if (!/^[A-Z][0-9][A-Z]/i.test(number)) {
          number = number.slice(0, -1);
        }
        break;
      case 4:
        if (!/^[A-Z][0-9][A-Z][0-9]/i.test(number)) {
          number = number.slice(0, -1);
        }
        break;
      case 5:
        if (!/^[A-Z][0-9][A-Z][0-9][A-Z]/i.test(number)) {
          number = number.slice(0, -1);
        }
        break;
      case 6:
        if (!/^[A-Z][0-9][A-Z][0-9][A-Z][0-9]/i.test(number)) {
          number = number.slice(0, -1);
        }
        break;
      default:
        number = number.slice(0, -1);
    }

    // split into parts
    const splitter = /.{1,3}/g;

    // put the parts together with separators
    number = number.substring(0, 6).match(splitter).join('-') + number.substring(6);

    // handle ending dash
    if (endingDash && number.length === 3) {
      number += '-';
    }

    return number;
  } else {
    // this isn't valid
    return '';
  }
};

/**
 * Formats a credit card expiration date by adding slashes where appropriate.
 */
export const formatExpiration = (number) => {
  // if we don't have a number, return
  if (!number) {
    return '';
  }

  // special case; user typed a slash
  const endingSlash = number.endsWith('/');

  // strip non-digits
  number = number.replace(/\D/g, '');

  // if we don't have a number any longer, return
  if (!number) {
    return '';
  }

  // split into parts
  const splitter = /.{1,2}/g;
  const split = number.match(splitter);

  // do we have a year?
  if (split.length > 1) {
    // complete 4 digit year?
    if (split.length === 3 && split[2].length === 2) {
      // put the parts together with a slash, moving to a 2 digit year
      number = `${split[0]} / ${split[2]}`;
    }

    // incomplete 4 digit year?
    else if (split.length === 3 && split[2].length === 1) {
      // put the parts together with a slash, leaving the incomplete year
      number = `${split[0]} / ${split[1]}${split[2]}`;
    }

    // 2 digit year?
    else if (split.length === 2) {
      // put the parts together with a slash, leaving a 2 digit year
      number = split.join(' / ');
    }
  }

  // handle ending dash
  if (endingSlash && number.length === 2) {
    number += ' /';
  }

  return number;
};
