import React from 'react';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* A retry prompt. */
export default class Retry extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h4 className="png-retry-message">
              Uh-oh! Something went left when it should have gone right.{' '}
              <span className="png-retry-message-link" onClick={this.props.onRefresh}>
                Try again?
              </span>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}
