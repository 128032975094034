import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';

import { BasePureComponent } from 'components/Base';

/* Reservation booking beacon. */
class Beacon extends BasePureComponent {
  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // create a script tag for the tracking beacon
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = `https://beacon.sojern.com/pixel/p/${this.props.id}`;
    if (this.props.options) {
      s.src += '?' + $.param(this.props.options);
    }

    // append it to the DOM
    this.instance.appendChild(s);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return <div ref={(el) => (this.instance = el)} />;
  }
}

// turn this into a container component
export default withRouter(connect()(Beacon));
