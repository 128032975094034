import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './scenes/Home';
import BookReservation from './scenes/Reservations/scenes/BookReservation';
import ViewReservations from './scenes/Reservations/scenes/ViewReservations';
import ViewReservation from './scenes/Reservations/scenes/ViewReservations/scenes/ViewReservation';
import CustomerProfile from './scenes/Customers/scenes/Profile';
import Locations from './scenes/Locations';
import Location from './scenes/Locations/scenes/Location';
import AboutUs from './scenes/AboutUs';
import MobileApps from './scenes/MobileApps';
import RefundPolicy from './scenes/RefundPolicy';
import PrivacyPolicy from './scenes/PrivacyPolicy';
import CorporateInquiry from './scenes/CorporateInquiry';
import TermsAndConditions from './scenes/TermsAndConditions';
import Info from './scenes/Info';
import './styles.scss';

/* The application's main content. This content changes as the user navigates. */
export default function Main(props) {
  return (
    <div className="png-main">
      <Switch>
        {/* home */}
        <Route exact path="/" component={Home} />

        {/* application routes */}
        <Route
          path="/reservations/edit/:facilityId/:customerId/:reservationId"
          component={BookReservation}
        />
        <Route path="/reservations/new/:facilityId/:start/:end" component={BookReservation} />
        <Route path="/reservations/new/:facilityId" component={BookReservation} />
        <Route path="/reservations/new" component={BookReservation} />
        <Route
          path="/reservations/:facilityId/:customerId/:reservationId"
          component={ViewReservation}
        />
        <Route path="/reservations" component={ViewReservations} />
        <Route path="/profile" component={CustomerProfile} />
        <Route path="/about" component={AboutUs} />
        <Route path="/info" component={Info} />
        <Route path="/refunds" component={RefundPolicy} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/corporateInquiry" component={CorporateInquiry} />
        <Route path="/mobile" component={MobileApps} />
        <Route path="/terms" component={TermsAndConditions} />
        <Route path="/locations/:facilityId" component={Location} />
        <Route path="/locations" component={Locations} />

        {/* invalid route */}
        <Route component={Home} />
      </Switch>
    </div>
  );
}
