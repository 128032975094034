import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, reduxForm, clearSubmitErrors, formValueSelector, change } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import './styles.scss';

/* Services form. */
class ServicesForm extends BaseForm {
  render() {
    // parent, for lifecycle logging
    super.render();

    // split the currently selected services
    const services = this.props.selectedServices ? this.props.selectedServices : [];

    // put IDs for selected services into a list to help later
    const serviceIDs = [];
    for (var i = 0; i < services.length; i++) {
      serviceIDs.push(services[i].id);
    }

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* services */}
        <div className="container-fluid png-reservation-book-services">
          {this.props.services.map((service) => (
            <div key={service.id} className="row">
              <div className="col">
                <div
                  className={
                    `container-fluid png-reservation-book-service` +
                    (serviceIDs.includes(service.id) ? '-selected' : '')
                  }
                  onClick={() => {
                    // see if it was already selected
                    const deselect = serviceIDs.includes(service.id);

                    // if this service exists in the list, remove it; this prevents duplicates
                    var list = [];
                    for (var i = 0; i < services.length; i++) {
                      if (services[i].id !== service.id) {
                        list.push(services[i]);
                      }
                    }

                    // if it was selected, add the current element
                    if (!deselect) {
                      list.push(service);
                    }

                    // update the form
                    this.props.dispatch(change(this.props.form, 'services', list));
                  }}
                >
                  <div className="row">
                    <div className="col">{service.description}</div>
                    <div className="col-4 text-right text-nowrap">${service.price.toFixed(2)}</div>
                  </div>
                  {service.notes && (
                    <div className="row png-reservation-book-service-notes">
                      <div className="col">{service.notes}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
ServicesForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(ServicesForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // selected service(s)
  selectedServices: formValueSelector(ownProps.form)(state, 'services'),
});

// turn this into a container component
ServicesForm = withRouter(connect(mapStateToProps, null)(ServicesForm));

// set default props
ServicesForm.defaultProps = {
  form: 'reservationForm',
};

export default ServicesForm;
