import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearSubmitErrors, Field, Form, initialize, reduxForm } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { renderEnhancedField } from 'util/form/renderers';

/* Location selection form. */
class LocationForm extends BaseForm {
  render() {
    // parent, for lifecycle logging
    super.render();

    // get facilities
    const { facilities } = this.props;

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* location */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="select"
              label="Location"
              name="facilityId"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Location"
              tooltip={`The location at which you will be parking`}
              required={true}
              disabled={this.props.submitting}
              onChange={(e) => {
                // reset the form to just the location
                this.props.resetForm(e.target.value);

                // capture the selected facility in local storage
                localStorage.setItem('selectedFacility', e.target.value);
              }}
              normalize={(value) => Number(value)}
            >
              {facilities && facilities.length > 0 && this.renderFacilityOptions(facilities, true)}
            </Field>
          </div>
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          {/* spacer */}
          <div className="form-group png-reservation-button col-7"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
LocationForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(LocationForm);

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // resets the form to just the location
  resetForm: (facilityId) => {
    dispatch(
      initialize(ownProps.form, {
        facilityId,
      }),
    );
  },
});

// turn this into a container component
LocationForm = withRouter(connect(null, mapDispatchToProps)(LocationForm));

// set default props
LocationForm.defaultProps = {
  form: 'reservationForm',
};

export default LocationForm;
