import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BasePureComponent } from 'components/Base';

/* An alert. Can be of flavor "info", "success", "warning", or "error". */
class Alert extends BasePureComponent {
  constructor(props) {
    // parent
    super(props);

    // capture props
    this.flavor = props.flavor != null ? props.flavor : 'info';

    // figure out the class based on the flavor
    this.typeClass = null;
    switch (this.flavor.toUpperCase()) {
      case 'ERROR':
        this.typeClass = 'alert-danger';
        break;
      case 'WARN':
      case 'WARNING':
        this.typeClass = 'alert-warning';
        break;
      case 'INFO':
        this.typeClass = 'alert-info';
        break;
      case 'SUCCESS':
        this.typeClass = 'alert-success';
        break;
      default:
        this.typeClass = 'alert-info';
        break;
    }

    // the callback when manually closed
    this.onClose = props.onClose;
  }

  render() {
    // parent
    super.render();

    // render
    return (
      // extra <div>: https://github.com/facebook/react/issues/6802#issuecomment-257875047
      <div>
        <div
          className={`alert ${this.typeClass} alert-dismissible fade show`}
          id={this.id}
          role="alert"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: this.props.text,
            }}
          />
          <button
            tye="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={this.props.onClose}
            title="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Alert = connect()(Alert);

// set prop types and required-ness
Alert.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

// set default props
Alert.defaultProps = {};

export default Alert;
