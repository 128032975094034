import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { BasePureComponent } from 'components/Base';
import { showBusy, hideBusy } from 'components/Busy/actions';
import Retry from 'components/Retry';
import { fetch as getContent } from 'entities/Content/actions';
import './styles.scss';

/* External content. */
class Content extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for entity and error tracking
    this.state = {
      fetchError: false,
      loading: false,
      content: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // load the content
    if (!this.state.loading) {
      this.loadContent(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if the content changed, re-fetch
    if (this.props.name && prevProps.name && this.props.name !== prevProps.name) {
      // reload
      this.loadData(this.props);
    }
  }

  // loads the page content
  loadContent({ getContent, name, showError, showBusy }) {
    // see if we have it in local storage
    var cachedContent = localStorage.getItem(`content:${name}`);

    // if we do, we'll use it, but we're still going to look for an update
    if (cachedContent) {
      // capture the content
      this.setState({
        fetchError: false,
        content: JSON.parse(cachedContent),
      });
    }

    // only show a loader if we don't have cached content
    if (!cachedContent) {
      this.setState({
        fetchError: false,
        loading: true,
        content: null,
      });
    }

    // get the content
    getContent(name, showBusy)
      .then((content) => {
        // capture the content
        this.setState({
          fetchError: false,
          content: content,
        });

        // cache it in local storage
        if (content) {
          localStorage.setItem(`content:${name}`, JSON.stringify(content));
        }
      })
      .catch((e) => {
        // we handle errors differently based on whether we found cached content
        if (!cachedContent) {
          // log it
          console.error('Error fetching content:', e);

          // flag it so we can show an error?
          if (showError) {
            this.setState({ fetchError: true });
          }
        } else {
          // log it
          console.warn('Error fetching content; using cached content:', e);
        }
      })
      .finally(() => {
        // no longer loading
        this.setState({ loading: false });
      });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div>
        {/* error? */}
        {this.state.fetchError && <Retry onRefresh={() => this.loadContent(this.props)} />}

        {/* still loading? */}
        {(this.props.showLoader || typeof this.props.showLoader === 'undefined') &&
          this.state.loading &&
          !this.state.fetchError && (
            <div className="container">
              <div className="row">
                <div className="col-12 text-center png-content-loading">
                  <SkeletonTheme baseColor="lightgrey" highlightColor="#edd128">
                    <Skeleton count={3} />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          )}

        {/* found content? */}
        {!this.state.loading && !this.state.fetchError && this.state.content && (
          <div
            className="png-content-content"
            dangerouslySetInnerHTML={{ __html: this.state.content.content }}
          />
        )}
      </div>
    );
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // getContent
  getContent: (name, showBusyIndicator = false) => {
    // show the busy indicator
    var busyId = null;
    if (showBusyIndicator) {
      busyId = dispatch(showBusy());
    }

    // get the content
    return dispatch(getContent(name)).finally(() => {
      // hide the busy indicator
      if (busyId) {
        dispatch(hideBusy(busyId));
      }
    });
  },
});

// turn this into a container component
export default withRouter(connect(null, mapDispatchToProps)(Content));
