import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import { go as navigate } from 'components/Navigate/actions';
import { disable as disableTestMode } from './actions';
import './styles.scss';

/* Test mode indicator. */
class TestMode extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    if (this.props.testMode) {
      return (
        <div className="png-test-mode d-print-none">
          <span onClick={this.props.disable}>
            <FontAwesomeIcon icon="mortar-pestle" />
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // enabled?
    testMode: (state.testMode && state.testMode.enabled) || localStorage.getItem('testMode'),
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // disable
  disable: () => {
    // update state
    dispatch(disableTestMode());

    // clear some items from local storage
    localStorage.removeItem('testMode');
    localStorage.removeItem('selectedFacility');

    // go home
    dispatch(navigate('home'));
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestMode));
