import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';

import { BasePureComponent } from 'components/Base';
import { go as navigate } from 'components/Navigate/actions';
import { toggle as toggleMenu } from 'components/Menu/actions';
import FPModal from 'components/FPModal';
import { showDialog as showFPPModal } from 'components/FPModal/actions';
import logo from 'images/logo-200.png';
import './styles.scss';

/* The application's header. */
class Header extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // the incredible shrinking header
    const header = this;
    $(document).on('scroll', function () {
      header.applyShrinkClasses();
    });
  }

  applyShrinkClasses() {
    // classes of elements to shrink
    const toShrink = [
      'png-header',
      'png-header-name',
      'png-header-logos',
      'png-header-icons',
      'png-fpp-icon',
      'png-hamburger-container',
      'png-hamburger-icon',
      'png-hamburger-bar1',
      'png-hamburger-bar2',
      'png-hamburger-bar3',
      'png-ribbon',
      'bm-menu-wrap',
    ];

    // apply them
    for (var i = 0; i < toShrink.length; i++) {
      if ($(document).scrollTop() > 100) {
        $(`.${toShrink[i]}`).addClass(`${toShrink[i]}-small`);
      } else {
        $(`.${toShrink[i]}`).removeClass(`${toShrink[i]}-small`);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // did the login state change?
    if (!!prevProps.customer !== !!this.props.customer) {
      this.applyShrinkClasses();
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div>
        <div id="png-header" className="png-header png-background">
          <div className="png-header-container d-flex flex-row flex-grow">
            <div id="png-hamburger-container" className="png-header-side png-hamburger-container">
              <div
                id="png-hamburger-icon"
                className={`png-hamburger-icon` + (this.props.menuOpen ? ' active' : '')}
                tabIndex="0"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
                onClick={() => this.props.toggleMenu()}
              >
                <div id="png-hamburger-bar1" className="png-hamburger-bar1" />
                <div id="png-hamburger-bar2" className="png-hamburger-bar2" />
                <div id="png-hamburger-bar3" className="png-hamburger-bar3" />
              </div>
            </div>
            <div className="flex-grow" />
            <div className="png-header-name-logos">
              <div
                className="text-center png-header-name"
                style={{
                  backgroundImage: `url(${logo})`,
                  backgroundRepeat: 'no-repeat',
                }}
                onClick={() => this.props.home()}
              />
            </div>
            <div className="flex-grow" />
            <div
              id="png-fpp-container"
              className="png-header-side png-fpp-container text-center png-clickable"
              onClick={() => this.props.fppModal()}
            >
              <div
                id="png-fpp-icon"
                className={`png-fpp-icon` + (this.props.customer ? ' png-fpp-icon-active' : '')}
                tabIndex="1"
                title={
                  this.props.customer
                    ? 'View your Frequent Parker card'
                    : 'Login to your Frequent Parker account'
                }
              >
                {this.props.customer && <FontAwesomeIcon icon="id-card" />}
                {!this.props.customer && (
                  <>
                    <span className="d-block d-sm-none">
                      <FontAwesomeIcon icon="sign-in-alt" />
                    </span>
                    <span className="d-none d-sm-inline-block">
                      <span className="png-login">Login</span>
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* the login/card modals; must sit outside the header */}
        <FPModal />
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // menu open?
  menuOpen: state.menu.open,

  // the existing customer, if there is one
  customer: state.context.customer,

  // current location
  location: state.router ? state.router.location : null,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // toggle menu
  toggleMenu: () => {
    dispatch(toggleMenu());
  },

  // open FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },

  // home
  home: () => {
    dispatch(navigate('home'));
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
