import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BasePage from 'components/Page';
import Loading from 'components/Loading';
import LocationDetails from '../../components/LocationDetails';

/* Single location page. */
class Location extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for location management
    this.state = {
      ...this.state,
      location: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // look for a specific location
    this.setLocation(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // look for a specific location
    this.setLocation(this.props);
  }

  setLocation(props) {
    if (props.match && props.match.params && props.match.params.facilityId) {
      // if we already have the desired facility, do nothing
      if (
        this.state.facility &&
        String(this.state.facility.id) === String(props.match.params.facilityId)
      ) {
        return;
      }

      // clear the existing facility
      this.setState({
        location: null,
      });

      // find this facility
      if (props.facilities) {
        for (var i = 0; i < props.facilities.length; i++) {
          if (String(props.facilities[i].id) === String(props.match.params.facilityId)) {
            // capture it
            this.setState({
              location: props.facilities[i],
            });
            break;
          }
        }
      }
    } else if (this.state.location) {
      // clear the location
      this.setState({
        location: null,
      });
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // location details
    if (this.state.location) {
      return (
        <>
          <LocationDetails facilityId={this.state.location.id} expanded={true} />
        </>
      );
    } else {
      // redirect to main locations page
      return <Loading />;
    }
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // facilities
  facilities: state.context.facilities,
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, null)(Location));
