import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BasePureComponent } from 'components/Base';

/**
 * A page showing that the site is undergoing maintenance.
 */
class Maintenance extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div
        style={{
          display: 'table',
          width: '100%',
          height: '90vh',
          padding: '50px',
        }}
      >
        <section
          style={{
            display: 'table-row',
          }}
        >
          <div
            className="align-middle text-center"
            style={{
              display: 'table-cell',
              maxWidth: '400px',
            }}
          >
            <h2>We're hard at work!</h2>
            <p>
              <span style={{ fontSize: '10rem', color: '#edd128' }}>
                <FontAwesomeIcon icon="hard-hat" />
              </span>
            </p>
            <p>We're doing some routine maintenance. We'll be back soon!</p>
          </div>
        </section>
      </div>
    );
  }
}

export default Maintenance;
