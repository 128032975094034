import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import { fetch as getConfig } from 'entities/Config/actions';
import './styles.scss';

/* Support chat integration. */
class SupportChat extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for display management
    this.state = {
      ...this.state,
      display: false,
    };

    // check config
    this.props.displayWidget().then((value) => {
      this.setState({ display: value });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // display it?
    if (this.state.display && this.instance) {
      // create the script tag
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;

      // populate it
      s.innerHTML =
        "(function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,'//png.ladesk.com/scripts/track.js',function(e){ LiveAgent.createButton('9108ee76', e); });";

      // append it to the DOM
      this.instance.appendChild(s);
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return <div ref={(el) => (this.instance = el)} />;
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // determines if the widget should be displayed
  displayWidget: () => {
    return dispatch(getConfig('web.enableSupportWidget'))
      .then((config) => {
        return !config || config.value.length === 0 || config.value.toLowerCase() !== 'false';
      })
      .catch((e) => {
        // display the widget
        console.error(
          "Error determining if we should display the support widget; we'll display it",
          e,
        );
        return true;
      });
  },
});

// turn this into a container component
export default withRouter(connect(null, mapDispatchToProps)(SupportChat));
