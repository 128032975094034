import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, reduxForm, clearSubmitErrors } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { renderEnhancedField } from 'util/form/renderers';

/* Reservation search form. */
class SearchForm extends BaseForm {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-search-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="form-row">
            <div className="form-group col has-error">
              <div className="png-form-error">{this.props.error}</div>
            </div>
          </div>
        )}

        {/* reservation location */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="select"
              label="Reservation Location"
              name="facilityId"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Reservation Location"
              tooltip={`The reservation's location`}
              required={true}
              disabled={this.props.submitting}
            >
              {this.props.facilities &&
                this.props.facilities.length > 0 &&
                this.renderFacilityOptions(this.props.facilities, true)}
            </Field>
          </div>
        </div>

        {/* email */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="email"
              label="Reservation Email Address"
              name="email"
              autoComplete="email"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Reservation Email Address"
              tooltip={`The email address under which the reservation was booked`}
              minLength={5}
              maxLength={128}
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* ID */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="number"
              label="Reservation ID"
              name="reservationId"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Reservation ID"
              tooltip={`The reservation ID`}
              minLength={1}
              maxLength={10}
              min={1}
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* search */}
        <div className="form-row">
          <div className="form-group col text-center">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={this.props.invalid || !this.state.htmlValid || this.props.submitting}
            >
              Search
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
SearchForm = reduxForm({
  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(SearchForm);

// turn this into a container component
SearchForm = withRouter(connect()(SearchForm));

// set default props
SearchForm.defaultProps = {
  form: 'reservationSearchForm',
};

export default SearchForm;
