import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, clearSubmitErrors, Field, Form, formValueSelector, reduxForm } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { renderEnhancedField } from 'util/form/renderers';

/* Vehicle form. */
class VehicleForm extends BaseForm {
  constructor(props) {
    // parent
    super(props);

    // all fields are optional, so we're valid by default
    this.state = {
      ...this.state,
      htmlValid: true,
    };
  }

  renderVehicleOptions(vehicles) {
    let items = [];
    items.push(<option key={0} value={''} />);
    for (var i = 0; i < vehicles.length; i++) {
      let vehicle = vehicles[i];
      let name = '';
      if (vehicle.color && vehicle.color.length > 0) {
        if (name.length > 0) {
          name += ' ';
        }
        name += vehicle.color;
      }
      if (vehicle.make && vehicle.make.length > 0) {
        if (name.length > 0) {
          name += ' ';
        }
        name += vehicle.make;
      }
      if (vehicle.model && vehicle.model.length > 0) {
        if (name.length > 0) {
          name += ' ';
        }
        name += vehicle.model;
      }
      if (name.length > 0) {
        name += ` (${vehicle.state} ${vehicle.license})`;
      } else {
        name += ` ${vehicle.state} ${vehicle.license}`;
      }
      items.push(
        <option
          key={`${vehicle.state}-${vehicle.license}`}
          value={`${vehicle.state}-${vehicle.license}`}
        >
          {name}
        </option>,
      );
    }
    return items;
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit((values) => {
          this.props.onSubmit({
            ...values,

            // I should hot have to do this, but I can't for the life
            // of me figure out why the vehicle is not being set on the
            // form after the change to make this the last page of a
            // post-pay flow; the prepay flow works fine because there is
            // another form after this one
            vehicle: {
              make: this.props.make,
              model: this.props.model,
              color: this.props.color,
              state: this.props.state,
              license: this.props.license,
            },
          });
        })}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* existing vehicles */}
        {this.props.customer &&
          this.props.customer.vehicles &&
          this.props.customer.vehicles.length > 0 && (
            <div className="form-row">
              <div className="form-group col has-error">
                <Field
                  type="select"
                  label="Existing Vehicles"
                  name="chooseVehicle"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="Existing Vehicles"
                  tooltip={`Choose from the vehicles in your profile`}
                  required={false}
                  disabled={this.props.submitting}
                  onChange={(e) => {
                    // find the vehicle
                    var vehicle = null;
                    for (var i = 0; i < this.props.customer.vehicles.length; i++) {
                      if (
                        e.target.value ===
                        `${this.props.customer.vehicles[i].state}-${this.props.customer.vehicles[i].license}`
                      ) {
                        vehicle = this.props.customer.vehicles[i];
                        break;
                      }
                    }

                    // if we found one, use the values
                    if (vehicle) {
                      this.props.dispatch(
                        change(this.props.form, 'vehicle.make', vehicle.make ? vehicle.make : ''),
                      );
                      this.props.dispatch(
                        change(
                          this.props.form,
                          'vehicle.model',
                          vehicle.model ? vehicle.model : '',
                        ),
                      );
                      this.props.dispatch(
                        change(
                          this.props.form,
                          'vehicle.color',
                          vehicle.color ? vehicle.color : '',
                        ),
                      );
                      this.props.dispatch(
                        change(
                          this.props.form,
                          'vehicle.state',
                          vehicle.state ? vehicle.state : '',
                        ),
                      );
                      this.props.dispatch(
                        change(
                          this.props.form,
                          'vehicle.license',
                          vehicle.license ? vehicle.license : '',
                        ),
                      );
                    }
                  }}
                >
                  {this.renderVehicleOptions(this.props.customer.vehicles)}
                </Field>
              </div>
            </div>
          )}

        {/* make/model/color */}
        <div className="form-row">
          <div className="form-group col-sm-4 has-error">
            <Field
              type="text"
              label="Make"
              name="vehicle.make"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Make"
              tooltip={`The make of this vehicle; 'Ford', for example`}
              maxLength="32"
              disabled={this.props.submitting}
            />
          </div>
          <div className="form-group col-sm-4 has-error">
            <Field
              type="text"
              label="Model"
              name="vehicle.model"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Make"
              tooltip={`The model of this vehicle; 'F-150', for example`}
              maxLength="32"
              disabled={this.props.submitting}
            />
          </div>
          <div className="form-group col-sm-4 has-error">
            <Field
              type="text"
              label="Color"
              name="vehicle.color"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Color"
              tooltip={`The color of this vehicle`}
              maxLength="32"
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* state/license */}
        <div className="form-row">
          <div className="form-group col-sm-5 has-error">
            <Field
              type="select"
              label="State/Province"
              name="vehicle.state"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="State/Province"
              tooltip={`The state or province in which the vehicle is registered`}
              required={
                this.props.make || this.props.model || this.props.color || this.props.license
              }
              disabled={this.props.submitting}
            >
              {this.renderStateOptions()}
            </Field>
          </div>
          <div className="form-group col-sm-3 has-error">
            <Field
              type="text"
              label="License"
              name="vehicle.license"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              normalize={(value) => {
                // only allow alphanumeric, spaces, and dashes
                return value.replace(/[^a-zA-Z0-9 -]/g, '').toUpperCase();
              }}
              placeholder="License"
              tooltip={`The license of this vehicle`}
              maxLength="8"
              required={this.props.make || this.props.model || this.props.color || this.props.state}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              {this.props.prepay ? 'Next' : this.props.original ? 'Update' : 'Book'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
VehicleForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // allow us to add new initial values
  enableReinitialize: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(VehicleForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // grab all values
  make: formValueSelector(ownProps.form)(state, 'vehicle.make'),
  model: formValueSelector(ownProps.form)(state, 'vehicle.model'),
  color: formValueSelector(ownProps.form)(state, 'vehicle.color'),
  state: formValueSelector(ownProps.form)(state, 'vehicle.state'),
  license: formValueSelector(ownProps.form)(state, 'vehicle.license'),
});

// turn this into a container component
VehicleForm = withRouter(connect(mapStateToProps, null)(VehicleForm));

// set default props
VehicleForm.defaultProps = {
  form: 'reservationForm',
};

export default VehicleForm;
