import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

import BasePage from 'components/Page';
import Content from 'components/Content';
import googlePlay from 'images/google-play-badge.png';
import appStore from 'images/app-store-badge.png';

/* Mobile apps page. */
export default class MobileApps extends BasePage {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div>
        <Content name="mobileApps" showLoader={false} showError={false} />
        <div className="container-fluid">
          <div className="row mt-5">
            {(isAndroid || (!isAndroid && !isIOS)) && (
              <div className="col text-center">
                <a href="https://play.google.com/store/apps/details?id=com.bookparkngo.parkngo">
                  <img src={googlePlay} alt="Get it on Google Play" width="150" />
                </a>
              </div>
            )}
            {(isIOS || (!isAndroid && !isIOS)) && (
              <div className="col text-center">
                <a href="https://itunes.apple.com/na/app/park-n-go-airport-parking/id998785022">
                  <img src={appStore} alt="Download it on the App Store" width="150" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
