import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import { clear } from './actions';

/* Navigation redirection. */
class Navigate extends BasePureComponent {
  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // avoid recursive navigation
    this.props.clear();
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    if (this.props.destination) {
      // this needs to stay in sync with the router mappings
      switch (this.props.destination) {
        case 'home':
          return <Redirect to="/" push />;
        case 'newReservation':
          return (
            <Redirect
              to={
                `/reservations/new` +
                (this.props.supplemental && this.props.supplemental.length > 0
                  ? `/${this.props.supplemental}`
                  : '')
              }
              push
            />
          );
        case 'findReservation':
          return <Redirect to="/reservations" push />;
        case 'viewProfile':
          return <Redirect to="/profile" push />;
        case 'locations':
          return <Redirect to="/locations" push />;
        case 'locationDetails':
          return (
            <Redirect
              to={`/locations${
                this.props.supplemental && this.props.supplemental.length > 0
                  ? `/${this.props.supplemental}`
                  : ''
              }`}
              push
            />
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // go anywhere?
    destination: state.navigate ? state.navigate.destination : null,

    // supplemental information
    supplemental: state.navigate ? state.navigate.supplemental : null,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // clear
  clear: () => {
    dispatch(clear());
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigate));
