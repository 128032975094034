import $ from 'jquery';

// sets HTML5 errors for a given field on a given form
export function setFieldError(formId, fieldId, error) {
  // sanity check
  if (!fieldId) {
    return;
  }

  // escape special characters in the ID
  fieldId = fieldId.replace('[', '\\[').replace(']', '\\]').replace('.', '\\.');

  // if we have a form ID, look for the form first
  let selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0] && error) {
    // ... set it
    if (field[0]) {
      field[0].setCustomValidity(error);
    }
  }
}

// clears HTML5 errors for a given field
export function clearFieldError(formId, fieldId) {
  // sanity check
  if (!fieldId) {
    return;
  }

  // escape special characters in the ID
  fieldId = fieldId.replace('[', '\\[').replace(']', '\\]').replace('.', '\\.');

  // if we have a form ID, look for the form first
  let selector = `#${fieldId}`;
  if (formId) {
    selector = `#${formId} ${selector}`;
  }

  // if the field exists...
  const field = $(selector);
  if (field && field[0]) {
    // ... clear it
    if (field[0]) {
      field[0].setCustomValidity(``);
    }
  }
}

// checks form HTML5 validity; optionally uses a slight delay to allow dynamic fields to render
export const checkValidity = (formObject, delay = true) => {
  // TODO: This is a kludge; we'd be better of rolling
  //       a custom Form class to hold this
  if (formObject.props && formObject.props.form) {
    if (delay) {
      const cvThis = formObject;
      setTimeout(function () {
        cvThis.setState({
          htmlValid: document.querySelector('#' + cvThis.props.form)
            ? document.querySelector('#' + cvThis.props.form).checkValidity()
            : false,
        });
      }, 200);
    } else {
      formObject.setState({
        htmlValid: document.querySelector('#' + formObject.props.form)
          ? document.querySelector('#' + formObject.props.form).checkValidity()
          : false,
      });
    }
  }
};
