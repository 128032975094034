import React from 'react';

import BasePage from 'components/Page';
import Content from 'components/Content';

/* Refund policy page. */
export default class RefundPolicy extends BasePage {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return <Content name="refundPolicy" showError={true} />;
  }
}
