/**
 * Sends an SOS.
 */
export function send(summary, details) {
  // try to get the most useful details
  if (details.stack) {
    details = details.stack;
  } else if (details.code && details.message) {
    details = 'Code: ' + details.code + '; Message: ' + details.message;
  } else if (typeof details !== 'string') {
    details = JSON.stringify(details);
  }

  // make sure they are safe to embed in the request
  details = escape(details);

  // if there is a summary, prepend it
  if (summary) {
    details = summary + ' - ' + details;
  }

  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'SEND_SOS',
      payload: {
        request: {
          method: 'post',
          url: `/admin/sos`,
          data: {
            details: details,
          },
        },
      },
    });
  };
}
