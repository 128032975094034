// action types
export const ENABLE = 'testMode/ENABLE';
export const DISABLE = 'testMode/DISABLE';

// enable test mode
export const enable = () => ({
  type: ENABLE,
});

// disable test mode
export const disable = () => ({
  type: DISABLE,
});
