import Barcode from 'react-barcode';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import { getIdByNetParkCode } from 'entities/Facility/util';
import { storeOnContext } from 'util/Context/actions';

/* A customer's FP card. */
export class FPCard extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="card png-home-fpp">
        <div className="card-header text-center png-background">
          <h5 className="mb-0">
            {this.props.customer.firstName} {this.props.customer.lastName}
          </h5>
        </div>
        <div className="card-body">
          <h5
            className="card-title text-center"
            onClick={() => this.props.refresh(this.props.customer, this.props.facilities)}
          >
            {this.props.customer.fpp ? this.props.customer.fpp : 0} Point
            {this.props.customer.fpp !== 1 && <span>s</span>}
          </h5>
          <div className="card-text text-center">
            <Barcode value={this.props.customer.alternateId.toUpperCase()} height={50} width={2} />
          </div>
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // facilities
    facilities: state.context.facilities,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // refresh profile
  refresh: (customer, facilities) => {
    // if not in the sandbox, refresh the customer
    if (customer && facilities) {
      const facilityId = getIdByNetParkCode(facilities, customer.locationCode);
      if (facilityId > 0) {
        dispatch(fetchCustomer(facilityId, customer.email, customer.alternateId))
          .then((customer) => {
            // refresh it on the context
            dispatch(storeOnContext('customer', customer));
          })
          .catch((e) => {
            // eat it
            console.error('Error refreshing customer', e);
          });
      }
    }
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FPCard));
