import React from 'react';
import { connect } from 'react-redux';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* Busy indicator. */
class Busy extends BasePureComponent {
  render() {
    // parent
    super.render();

    // render
    if (this.props.busy && this.props.busy.length > 0) {
      return (
        <div id="png-overlay" className="d-block">
          <div className="png-busy">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated png-busy-bar png-background"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="100"
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // busy?
    busy: state.busy ? state.busy.busy : [],
  };
}

// turn this into a container component
Busy = connect(mapStateToProps)(Busy);

// set prop types and required-ness
Busy.propTypes = {};

// set default props
Busy.defaultProps = {};

export default Busy;
