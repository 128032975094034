import * as a from './actions';

// define the default state; this is all persisted to local storage and therefore must remain lightweight
const defaultState = {};

// define the reducer
export const context = (state = defaultState, action) => {
  switch (action.type) {
    // clears all items in the context
    case a.CLEAR_ALL:
      return defaultState;

    // clears one item in the context
    case a.CLEAR_ONE:
      return {
        ...state,
        [action.key]: null,
      };

    // stores an item in the context
    case a.STORE:
      return {
        ...state,
        [action.key]: action.data,
      };

    // returns the current state
    default:
      return state;
  }
};
