import React from 'react';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* Confirm cancel dialog. */
export default class ConfirmCancel extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="container-fluid png-reservation-view-cancel-confirm png-background">
        <div className="row">
          <div className="col">
            <h1>Are you sure?</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>Do you really want to cancel this reservation?</p>
          </div>
        </div>
        <div className="row">
          <div className="col-6 text-left">
            <button className="btn btn-primary btn-lg" onClick={this.props.onClose}>
              Nope
            </button>
          </div>
          <div className="col-6 text-right">
            <button
              className="btn btn-primary btn-lg"
              onClick={() => {
                // perform logic
                this.props.onConfirm();

                // close the dialog
                this.props.onClose();
              }}
            >
              I'm Sure
            </button>
          </div>
        </div>
      </div>
    );
  }
}
