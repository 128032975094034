// must come first
import outdatedBrowserRework from 'outdated-browser-rework';
import 'outdated-browser-rework/dist/style.css';

// third-party imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowRight,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCompass,
  faEnvelope,
  faEquals,
  faExclamationCircle,
  faHardHat,
  faHome,
  faIdCard,
  faInfoCircle,
  faMapMarkerAlt,
  faMinus,
  faMobile,
  faMortarPestle,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faQuestion,
  faSearch,
  faSignInAlt,
  faSpinner,
  faSyncAlt,
  faTimes,
  faTrashAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { BrowserRouter, withRouter } from 'react-router-dom';

// our imports
import Alerts from 'components/Alerts';
import { addAlert } from 'components/Alerts/actions';
import { BaseComponent } from 'components/Base';
import Busy from 'components/Busy';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Loading from 'components/Loading';
import Menu from 'components/Menu';
import Navigate from 'components/Navigate';
import ScrollToTop from 'components/ScrollToTop';
import SupportChat from 'components/SupportChat';
import TestMode from 'components/TestMode';
import { fetch as fetchContent } from 'entities/Content/actions';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import { getIdByNetParkCode } from 'entities/Facility/util';
import { fetch as getStatus } from 'entities/Status/actions';
import { sortArray } from 'util/index';
import { hideBusy } from './components/Busy/actions';
import { enable as enableTestMode } from './components/TestMode/actions';
import { fetchOpen as fetchFacilities } from './entities/Facility/actions';
import ErrorBoundary from './ErrorBoundary';
import Main from './Main';
import Maintenance from './Maintenance';
import { storeOnContext } from './util/Context/actions';

// load the Font Awesome icons that we use
library.add(
  faSpinner,
  faMortarPestle,
  faPlus,
  faMinus,
  faTimes,
  faEquals,
  faQuestion,
  faArrowRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faUser,
  faPlusCircle,
  faSyncAlt,
  faCompass,
  faSearch,
  faHome,
  faPencilAlt,
  faTrashAlt,
  faSignInAlt,
  faIdCard,
  faExclamationCircle,
  faInfoCircle,
  faHardHat,
  faGoogle,
  faFacebook,
  faMapMarkerAlt,
  faEnvelope,
  faMobile,
);

/**
 * The main application.
 */
class App extends BaseComponent {
  constructor(props) {
    super(props);

    // test mode flag set?
    var testMode = false;
    if (new URLSearchParams(document.location.search).get('test')) {
      localStorage.setItem('testMode', true);
      this.props.dispatch(enableTestMode());
      localStorage.removeItem('selectedFacility');
      testMode = true;
    } else if (localStorage.getItem('testMode')) {
      this.props.dispatch(enableTestMode());
      testMode = true;
    }

    // fetch facilities
    this.props.dispatch(fetchFacilities(testMode)).then((facilities) => {
      // sort by name
      sortArray(facilities, 'name');

      // store them on the context
      this.props.dispatch(storeOnContext('facilities', facilities));

      // if we have a customer and are not in test mode, refresh it
      if (this.props.customer && facilities) {
        const facilityId = getIdByNetParkCode(facilities, this.props.customer.locationCode);
        if (facilityId > 0) {
          this.props
            .dispatch(
              fetchCustomer(facilityId, this.props.customer.email, this.props.customer.alternateId),
            )
            .then((customer) => {
              // refresh it on the context, but don't overwrite the object reference
              this.props.dispatch(
                storeOnContext('customer', Object.assign(this.props.customer, customer)),
              );
            })
            .catch((e) => {
              // eat it
              console.error('Error refreshing customer', e);
            });
        }
      }
    });

    // check for global alert
    this.props
      .dispatch(fetchContent('alert'))
      .then((response) => {
        // if one is set, display it
        if (response.content && response.content.trim().length > 0) {
          this.props.dispatch(addAlert('warning', response.content.trim(), 10000));
        }
      })
      .catch((e) => {
        // eat it
        console.error('Error fetching global alert', e);
      });

    // for maintenance mode
    this.state = {
      ...this.state,
      dataLoadError: false,
      dataLoading: true,
    };

    // fetch version; this will tell us if the hub is reachable
    this.props
      .dispatch(getStatus())
      .then((status) => {
        // for debugging
        console.debug('Status', status);

        // make sure the web client is enabled
        if (status.clients && status.clients.web && status.clients.web.status === 'DOWN') {
          // nope
          this.setState({ dataLoading: false, dataLoadError: true });
        } else {
          // we're good
          this.setState({ dataLoading: false, dataLoadError: false });

          // store the relevant portion on the context; we individually store the properties
          // rather than blindly storing the whole object just to make sure that what we think
          // will be there is actually there
          this.props.dispatch(
            storeOnContext('status', {
              enabled: true,
              parking: status.clients?.web?.parking?.status === 'UP',
              payments: {
                creditCard: status.clients?.web?.payments?.creditCard?.status === 'UP',
                applePay: status.clients?.web?.payments?.applePay?.status === 'UP',
                googlePay: status.clients?.web?.payments?.googlePay?.status === 'UP',
                payPal: status.clients?.web?.payments?.payPal?.status === 'UP',
              },
            }),
          );
        }
      })
      .catch(() => {
        // this isn't perfect, but if we failed to fetch version, we're probably offline
        this.setState({ dataLoading: false, dataLoadError: true });
      });

    // the status fetch should be very fast
    setTimeout(() => {
      if (this.state.dataLoading) {
        // give up
        this.setState({ dataLoading: false, dataLoadError: true });
      }
    }, 5000);
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if the test flag changed, fetch facilities
    if (prevProps.testMode !== this.props.testMode) {
      this.props.dispatch(fetchFacilities(this.props.testMode)).then((facilities) => {
        // store them on the context
        this.props.dispatch(storeOnContext('facilities', facilities));
      });
    }
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // fire up the outdated browser check
    outdatedBrowserRework({
      browserSupport: {
        Brave: 48,
        Chrome: 48,
        Edge: 28,
        Safari: 9.1,
        'Mobile Safari': 9.1,
        Firefox: 45,
        Opera: 35,
        Vivaldi: 1,
        IE: false,
        FBAN: true,
        FBAV: true,
        Instagram: true,
      },
      requireChromeOnAndroid: false,
      isUnknownBrowserOK: false,
      messages: {
        en: {
          outOfDate: `<span style="text-transform: none;">That's an old browser you've got there!</span>`,
          update: {
            web:
              '<span style="text-transform: none; font-size: 1.2rem; line-height: 1.2;">' +
              "There is a decent chance that the site won't look or function correctly in your browser. " +
              "You can try, but you've been warned. Your best bet is to update your browser. That would " +
              'make us happy, and we think it will make you happy too.' +
              '</span>',
            googlePlay:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please install ' +
              'a modern browser from Google Play</span>',
            appStore:
              '<span style="text-transform: none; line-height: 1.2;">To make sure the site works properly, please ' +
              'update iOS from the Settings App</span>',
          },
          url: 'http://outdatedbrowser.com/',
          callToAction: 'Update my browser now',
          close: 'Close',
        },
      },
    });

    // tell search engines not to crawl QA (and local, just for testing)
    if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'qa') {
      const head = document.getElementsByTagName('head');
      if (head && head.length > 0) {
        const meta = document.createElement('meta');
        meta.name = 'robots';
        meta.content = 'noindex, nofollow';
        head[0].appendChild(meta);
      }
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // if we are here, it's a new request or a refresh; in either case, we don't want a lingering busy indicator
    this.props.dispatch(hideBusy());

    // render
    return (
      <div>
        {/* common components */}
        <div>
          <Busy />
          <Alerts />
          {!this.props.testMode && <SupportChat />}
          <TestMode />
        </div>

        {/* application content */}
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <div id="png-app">
              <div style={{ width: '100%', height: '100vh' }}>
                {/* still loading */}
                {this.state.dataLoading ? (
                  <div className="mt-5">
                    <Loading />
                  </div>
                ) : this.state.dataLoadError ? (
                  <>
                    <Maintenance />
                  </>
                ) : (
                  <>
                    <nav>
                      <Navigate />
                      <Menu />
                      <Header />
                    </nav>
                    <main id="png-content">
                      <ErrorBoundary>
                        <Main />
                      </ErrorBoundary>
                    </main>
                    <footer>
                      <Footer />
                    </footer>
                  </>
                )}
              </div>
            </div>
          </ScrollToTop>
        </BrowserRouter>
        <div className="png-outdated">
          <div id="outdated" />
        </div>
      </div>
    );
  }
}

// enable cookies
App = withCookies(App);

// map state to properties relevant to this component
const mapStateToProps = (state) => ({
  // the existing customer, if there is one
  customer: state.context.customer,

  // test mode?
  testMode: state.testMode.enabled || localStorage.getItem('testMode'),
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, null)(App));
