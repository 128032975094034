/**
 * Fetches a payment token.
 */
export function fetchToken() {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_PAYMENT_TOKEN',
      payload: {
        request: {
          method: 'get',
          url: `/payments/token`,
        },
      },
    }).then((response) => {
      // pull out the token
      if (response) {
        return response.token;
      } else {
        return null;
      }
    });
  };
}
