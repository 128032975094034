import * as a from './actions';
import { TestMode as stateModel } from './state';

// define the reducer
export const testMode = (state = stateModel, action) => {
  switch (action.type) {
    // enable test mode
    case a.ENABLE:
      return {
        ...state,
        enabled: true,
      };

    // disable test mode
    case a.DISABLE:
      return {
        ...state,
        enabled: false,
      };

    // returns the current state
    default:
      return state;
  }
};
