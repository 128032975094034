import React from 'react';

import BasePage from 'components/Page';
import Content from 'components/Content';

/* Terms & conditions page. */
export default class TermsAndConditions extends BasePage {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return <Content name="termsAndConditions" showError={true} />;
  }
}
