import { BasePureComponent } from 'components/Base';

/**
 * A base page pure component from which all other pages should extend.
 *
 * Note that React discourages inheritance. We do it because I couldn't
 * figure out a better way to handle behavior that cuts across every
 * page of the app without sprinkling boilerplate code everywhere.
 *
 * Let the record show that I'm not happy with this solution and am in
 * favor of replacing it with a cleaner solution should one be found.
 */
export default class BasePage extends BasePureComponent {
  //
  // nothing in here right now
  //
}
