import * as a from './actions';
import { Navigate as stateModel } from './state';

// define the reducer
export const navigate = (state = stateModel, action) => {
  switch (action.type) {
    // navigate somewhere
    case a.GO:
      return {
        ...state,
        destination: action.destination,
        supplemental: action.supplemental,
      };

    // clear navigation
    case a.CLEAR:
      return {
        ...state,
        destination: null,
        supplemental: null,
      };

    // returns the current state
    default:
      return state;
  }
};
