import $ from 'jquery';
import 'moment-timezone';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import Content from 'components/Content';
import SojernBeacon from 'components/SojernBeacon';
import { getIdByFacilityCode } from 'entities/Facility/util';
import { formatPhone } from 'util/formatters';
import './styles.scss';

/* Reservation details. */
class ReservationDetails extends BasePureComponent {
  clearBeacon() {
    // only show the beacon once
    const { newBooking, ...newState } = this.props.location.state;
    this.props.history.replace(this.props.location.pathname + this.props.location.search, newState);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // timestamps on the reservation are in the reservation's time zone, but if we simply format them
    // as strings, they will be in the browser's time zone; therefore, we need to convert them to
    // the facility's time zone before formatting them
    const formatTime = (timestamp, facility, format = 'ddd, M/D/YY [at] h:mm A') => {
      // get the browser's time zone
      const clientTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // if we found a facility (which we should have), use it's time zone; otherwise, use the client's
      let facilityTZ = clientTZ;
      if (facility) {
        facilityTZ = facility.timeZone;
      }

      // format the timestamp in the facility's time zone
      return moment.unix(timestamp).tz(facilityTZ).format(format);
    };

    // the reservation
    const reservation = this.props.reservation;

    // the customer
    const customer = reservation.customer;

    // the reservation vehicle
    const vehicle = reservation.vehicle;

    // figure out the facility
    var facility = null;
    if ((reservation.locationCode || reservation.facilityId) && this.props.facilities) {
      var facilityId = reservation.facilityId;
      if (!facilityId) {
        facilityId = getIdByFacilityCode(this.props.facilities, reservation.facilityCode);
      }
      for (var i = 0; i < this.props.facilities.length; i++) {
        if (facilityId === this.props.facilities[i].id) {
          facility = this.props.facilities[i];
          break;
        }
      }
    }

    // date format
    var format = 'LLLL';
    if ($(window).width() < 350) {
      format = 'l h:mm A';
    } else if ($(window).width() < 910) {
      format = 'ddd, l h:mm A';
    }

    // make sure we have some key values
    if (!reservation.prepaid) {
      reservation.prepaid = 0;
    }
    if (!reservation.payOnExit) {
      reservation.payOnExit = 0;
    }

    // narrow display?
    const narrow = this.props.narrow;

    // maps link for facility
    var facilityLink = '';
    if (facility && facility.address) {
      facilityLink = `https://www.google.com/maps?q=${
        facility.address.street ? facility.address.street : ''
      } ${facility.address.city ? facility.address.city : ''}, ${
        facility.address.state ? facility.address.state : ''
      } ${facility.address.zip ? facility.address.zip : ''}`;
    }

    return (
      <div className="png-reservation-details">
        <div className="row">
          <div className="col-sm-12">
            <div className="container-fluid png-reservation-details-section">
              <div className="row png-reservation-details-section-header">
                <div className="col">Reservation</div>
              </div>
              {reservation.id && (
                <div className="row png-reservation-details-item">
                  <div className="col-sm-4 png-reservation-details-name">ID</div>
                  <div className="col-sm-8 png-reservation-details-value">{reservation.id}</div>
                </div>
              )}
              {reservation.status && (
                <div className="row png-reservation-details-item">
                  <div className="col-sm-4 png-reservation-details-name">Status</div>
                  <div className="col-sm-8 png-reservation-details-value">
                    {(function () {
                      switch (reservation.status) {
                        case 'pending':
                          return (
                            <span
                              className="text-info"
                              title="This reservation has been booked but not yet redeemed"
                            >
                              Booked
                            </span>
                          );
                        case 'canceled':
                          return (
                            <>
                              <span
                                className="text-danger"
                                title={
                                  reservation.cancelDate
                                    ? 'This reservation was canceled ' +
                                      moment.unix(reservation.cancelDate).format('l [at] h:mm A')
                                    : 'This reservation has been canceled'
                                }
                              >
                                Canceled
                              </span>
                              {reservation.cancelDate && (
                                <span>
                                  {' '}
                                  {moment.unix(reservation.cancelDate).format('l [at] h:mm A')}
                                </span>
                              )}
                            </>
                          );
                        case 'redeemed':
                          return <span title="This reservation has been completed">Completed</span>;
                        case 'missed':
                          return <span title="This reservation was never redeemed">Missed</span>;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              )}
              {reservation.start && (
                <div className="row png-reservation-details-item">
                  <div className="col-sm-4 png-reservation-details-name">Check In</div>
                  <div className="col-sm-8 png-reservation-details-value">
                    {formatTime(reservation.start, facility, format)}
                  </div>
                </div>
              )}
              {reservation.end && (
                <div className="row png-reservation-details-item">
                  <div className="col-sm-4 png-reservation-details-name">Check Out</div>
                  <div className="col-sm-8 png-reservation-details-value">
                    {formatTime(reservation.end, facility, format)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {facility && (
            <div className={`col-sm-` + (narrow ? 6 : 4)}>
              <div className="container-fluid png-reservation-details-section">
                <div className="row png-reservation-details-section-header">
                  <div className="col">Facility</div>
                </div>
                {facility.name && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-name">{facility.name}</div>
                  </div>
                )}
                {facility.address && (
                  <div>
                    <div className="row png-reservation-details-item">
                      <div className="col png-reservation-details-value">
                        <a href={facilityLink} target="_blank" rel="noopener noreferrer">
                          {facility.address.street && (
                            <span>
                              {facility.address.street}
                              <br />
                            </span>
                          )}
                          {facility.address.street2 && (
                            <span>
                              {facility.address.street2}
                              <br />
                            </span>
                          )}
                          {(facility.address.city ||
                            facility.address.state ||
                            facility.address.zip) && (
                            <span>
                              {facility.address.city ? facility.address.city + ', ' : ''}{' '}
                              {facility.address.state ? facility.address.state + ' ' : ''}{' '}
                              {facility.address.zip ? facility.address.zip : ''}
                            </span>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {facility.email && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-value">
                      <a href={`mailto:${facility.email}`}>{facility.email}</a>
                    </div>
                  </div>
                )}
                {facility.phone && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-value">
                      <a href={`tel:${facility.phone}`}>{formatPhone(String(facility.phone))}</a>
                    </div>
                  </div>
                )}
                {facility.notes && (!reservation.status || reservation.status === 'pending') && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-notes">{facility.notes}</div>
                  </div>
                )}
              </div>
            </div>
          )}
          {customer && (
            <div className={`col-sm-` + (narrow ? 6 : 4)}>
              <div className="container-fluid png-reservation-details-section">
                <div className="row png-reservation-details-section-header">
                  <div className="col">You</div>
                </div>
                {(customer.firstName || customer.lastName) && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-name">
                      {customer.firstName} {customer.lastName}
                    </div>
                  </div>
                )}
                {customer.address && (
                  <div>
                    {customer.address.street && (
                      <div className="row png-reservation-details-item">
                        <div className="col png-reservation-details-value">
                          {customer.address.street}
                        </div>
                      </div>
                    )}
                    {customer.address.street2 && (
                      <div className="row png-reservation-details-item">
                        <div className="col png-reservation-details-value">
                          {customer.address.street2}
                        </div>
                      </div>
                    )}
                    {(customer.address.city || customer.address.state || customer.address.zip) && (
                      <div className="row png-reservation-details-item">
                        <div className="col png-reservation-details-value">
                          {customer.address.city ? customer.address.city + ', ' : ''}{' '}
                          {customer.address.state ? customer.address.state + ' ' : ''}{' '}
                          {customer.address.zip ? customer.address.zip : ''}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {customer.email && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-value">
                      <a href={`mailto:${customer.email}`}>{customer.email}</a>
                    </div>
                  </div>
                )}
                {customer.personalPhone && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-value">
                      <a href={`tel:${customer.personalPhone}`}>
                        {formatPhone(String(customer.personalPhone))}
                      </a>
                    </div>
                  </div>
                )}
                {customer.workPhone && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-value">
                      <a href={`tel:${customer.workPhone}`}>
                        {formatPhone(String(customer.workPhone))}
                      </a>
                    </div>
                  </div>
                )}
                {customer.alternateId && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-fp">
                      <span className="font-weight-bold">FP Number</span>{' '}
                      {customer.alternateId.toUpperCase()}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {false &&
            vehicle &&
            (vehicle.make || vehicle.model || vehicle.color || vehicle.license) && (
              <div className={`col-sm-` + (narrow ? 6 : 4)}>
                <div className="container-fluid png-reservation-details-section">
                  <div className="row png-reservation-details-section-header">
                    <div className="col">Vehicle</div>
                  </div>
                  {vehicle.make && (
                    <div className="row png-reservation-details-item">
                      <div className="col-4 png-reservation-details-name">Make</div>
                      <div className="col png-reservation-details-value">{vehicle.make}</div>
                    </div>
                  )}
                  {vehicle.model && (
                    <div className="row png-reservation-details-item">
                      <div className="col-4 png-reservation-details-name">Model</div>
                      <div className="col png-reservation-details-value">{vehicle.model}</div>
                    </div>
                  )}
                  {vehicle.color && (
                    <div className="row png-reservation-details-item">
                      <div className="col-4 png-reservation-details-name">Color</div>
                      <div className="col png-reservation-details-value">{vehicle.color}</div>
                    </div>
                  )}
                  {vehicle.license && (
                    <div className="row png-reservation-details-item">
                      <div className="col-4 png-reservation-details-name">License</div>
                      <div className="col png-reservation-details-value">
                        {vehicle.license}
                        {vehicle.state && <span> ({vehicle.state})</span>}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
        <div className="row">
          {reservation.services && reservation.services.length > 0 && (
            <div className="col-sm-6">
              <div className="container-fluid png-reservation-details-section">
                <div className="row png-reservation-details-section-header">
                  <div className="col">Additional Services</div>
                </div>
                {reservation.services.map((service) => {
                  return (
                    <div key={service.id} className="row png-reservation-details-item">
                      <div className="col-8 png-reservation-details-value">
                        {service.description}
                      </div>
                      <div className="col-4 text-right text-nowrap png-reservation-details-value">
                        ${service.price.toFixed(2)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {reservation.status !== 'canceled' && (
            <div className="col-sm-6">
              <div className="container-fluid png-reservation-details-section png-reservation-details-charges">
                <div className="row png-reservation-details-section-header">
                  <div className="col">Charges</div>
                </div>
                {reservation.fppRedeemed > 0 && (
                  <div className="row png-reservation-details-item">
                    <div className="col-8 font-weight-bold">Points Used</div>
                    <div className="col-4 text-right text-nowrap png-reservation-details-value">
                      {reservation.fppRedeemed}
                    </div>
                  </div>
                )}
                {(reservation.fppRedeemed > 0 || reservation.prepaid > 0) && (
                  <div className="row png-reservation-details-item">
                    <div className="col-8 font-weight-bold">
                      {!reservation.status || reservation.status === 'pending' ? 'Pay' : 'Paid'} at
                      Booking
                    </div>
                    <div className="col-4 text-right text-nowrap png-reservation-details-value">
                      ${reservation.prepaid.toFixed(2)}
                    </div>
                  </div>
                )}
                {(reservation.fppRedeemed > 0 || reservation.payOnExit > 0) && (
                  <div className="row png-reservation-details-item">
                    <div className="col-8 font-weight-bold">
                      {!reservation.status || reservation.status === 'pending' ? 'Pay' : 'Paid'} on
                      Exit
                    </div>
                    <div className="col-4 text-right text-nowrap png-reservation-details-value">
                      ${reservation.payOnExit.toFixed(2)}
                    </div>
                  </div>
                )}
                {reservation.status === 'redeemed' && reservation.payOnExit > 0 && (
                  <div className="row png-reservation-details-item">
                    <div className="col png-reservation-details-notes">
                      The charges listed are according to the original booking. If your actual stay
                      was longer or shorter, the actual amount paid on exit may differ.
                    </div>
                  </div>
                )}
                {reservation.payment && reservation.payment.type && (
                  <div>
                    {reservation.payment.type === 'creditCard' &&
                      reservation.payment.creditCard &&
                      reservation.payment.creditCard.number && (
                        <div className="row png-reservation-details-item">
                          <div className="col-8">
                            <span className="font-weight-bold">Payment Card</span>
                          </div>
                          <div className="col-4 text-right text-nowrap png-reservation-details-value">
                            {reservation.payment.creditCard.issuer &&
                              reservation.payment.creditCard.issuer === 'visa' && (
                                <span>Visa </span>
                              )}
                            {reservation.payment.creditCard.issuer &&
                              reservation.payment.creditCard.issuer === 'mastercard' && (
                                <span>MC </span>
                              )}
                            {reservation.payment.creditCard.issuer &&
                              reservation.payment.creditCard.issuer === 'discover' && (
                                <span>Disc </span>
                              )}
                            {reservation.payment.creditCard.issuer &&
                              reservation.payment.creditCard.issuer === 'am_ex' && (
                                <span>AmEx </span>
                              )}
                            {reservation.payment.creditCard.number.substr(
                              reservation.payment.creditCard.number.length - 4,
                            )}
                          </div>
                        </div>
                      )}
                    {reservation.payment.type === 'payPal' && (
                      <div className="row png-reservation-details-item">
                        <div className="col-8">
                          <span className="font-weight-bold">Payment Method</span>
                        </div>
                        <div className="col-4 text-right text-nowrap png-reservation-details-value">
                          PayPal
                        </div>
                      </div>
                    )}
                    {reservation.payment.type === 'googlePay' && (
                      <div className="row png-reservation-details-item">
                        <div className="col-8">
                          <span className="font-weight-bold">Payment Method</span>
                        </div>
                        <div className="col-4 text-right text-nowrap png-reservation-details-value">
                          Google Pay
                        </div>
                      </div>
                    )}
                    {reservation.payment.type === 'applePay' && (
                      <div className="row png-reservation-details-item">
                        <div className="col-8">
                          <span className="font-weight-bold">Payment Method</span>
                        </div>
                        <div className="col-4 text-right text-nowrap png-reservation-details-value">
                          Apple Pay
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {(!reservation.status || reservation.status === 'pending') && (
          <Content name="reservationLegal" showError={false} />
        )}

        {/* booking beacon */}
        {this.props.displayBeacon && (
          <div>
            {this.clearBeacon()}
            <SojernBeacon
              id="3661"
              options={{
                cid: `${reservation.id}|${formatTime(
                  reservation.start,
                  facility,
                  'YYYY-MM-DD',
                )}|${formatTime(reservation.end, facility, 'YYYY-MM-DD')}|${
                  reservation.locationCode
                }`,
                rconfno: '[confirmation_number]',
                rd1: '[arrival date]',
                rd2: '[departure date]',
                ra2: '[location]',
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, ownProps) {
  return {
    // facilities
    facilities: state.context.facilities,

    // beacon
    displayBeacon:
      ownProps.location && ownProps.location.state && ownProps.location.state.newBooking,
  };
}

// turn this presentation component into a container component
export default withRouter(connect(mapStateToProps, null)(ReservationDetails));
