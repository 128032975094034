import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BasePage from 'components/Page';
import Content from 'components/Content';
import { fetchById as fetchFacility } from 'entities/Facility/actions';
import { go as navigate } from 'components/Navigate/actions';
import { formatPhone } from 'util/formatters';
import { getMapPin, getMapStyles } from 'util/index';
import './styles.scss';

/* Location details. */
class LocationDetails extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for facility management
    this.state = {
      ...this.state,
      facility: null,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // if we don't already have it, we need to load the fleshed out facility
    if (!this.state.facility || this.state.facility.id !== this.props.facilityId) {
      this.loadFacility(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we don't already have it, we need to load the fleshed out facility
    if (!this.state.facility || this.state.facility.id !== this.props.facilityId) {
      this.loadFacility(this.props);
    }
  }

  // loads the facility
  loadFacility(props) {
    // clear the existing facility
    this.setState({ facility: null });

    // fetch a new one
    props.fetchById(props.facilityId, props.testMode).then((facility) => {
      // if we got one, put it on the state
      if (facility) {
        this.setState({ facility: facility });

        // tracking beacon if in expanded mode
        if (props.expanded && facility.webBeacon && facility.webBeacon.length > 0) {
          // create a script tag for the tracking beacon
          const s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = facility.webBeacon;

          // append it to the DOM
          this.instance.appendChild(s);
        }
      }
    });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // map styles
    const mapStyles = getMapStyles(false);

    // the facility
    const facility = this.state.facility;

    // maps link for facility
    var facilityLink = '';
    var FacilityMap;
    var poi = [];
    if (facility && facility.address) {
      facilityLink = `https://www.google.com/maps?q=${
        facility.address.street ? facility.address.street : ''
      } ${facility.address.city ? facility.address.city : ''}, ${
        facility.address.state ? facility.address.state : ''
      } ${facility.address.zip ? facility.address.zip : ''}`;

      // this is a kludge because it forces us to understand the facility IDs,
      // but we need to slightly adjust the zoom for some facilities
      var zoom = 12.0;
      switch (facility.code) {
        case 'MSP':
          zoom = 11.8;
          break;
        case 'RIC':
          zoom = 12.6;
          break;
        default:
          // leave it alone
          break;
      }

      // the map
      const lat = facility.location.latitude;
      const lng = facility.location.longitude;
      FacilityMap = withScriptjs(
        withGoogleMap((props) => (
          <GoogleMap
            ref={props.onMapLoad}
            defaultZoom={zoom}
            defaultCenter={{ lat, lng }}
            onClick={props.onMapClick}
            defaultOptions={{ styles: mapStyles }}
          >
            {props.pointsOfInterest.map((poi) => (
              <MarkerWithLabel key={poi.label} {...poi.marker}>
                <div>{poi.label && poi.label}</div>
              </MarkerWithLabel>
            ))}
          </GoogleMap>
        )),
      );

      // add the facility as a POI
      const id = facility.id;
      poi.push({
        marker: {
          key: id,
          title: facility.name,
          labelClass: 'png-locations-map-label-primary',
          labelAnchor: { x: 0, y: 0 },
          position: {
            lat,
            lng,
          },
          icon: getMapPin(),
        },
      });

      // TODO: points of interest
      if (false && facility.pointsOfInterest) {
        for (var i = 0; i < facility.pointsOfInterest.length; i++) {
          const name = facility.pointsOfInterest[i].name;
          const lat = facility.pointsOfInterest[i].location.latitude;
          const lng = facility.pointsOfInterest[i].location.longitude;
          poi.push({
            label: name,
            marker: {
              key: name,
              labelClass: 'png-locations-map-label-poi',
              labelAnchor: { x: 0, y: 0 },
              position: {
                lat,
                lng,
              },
            },
          });
        }
      }
    }

    // show expanded details
    const expanded = this.props.expanded;

    // render
    return (
      <div ref={(el) => (this.instance = el)}>
        {facility && (
          <div>
            <div className={`container-fluid png-location-details` + (!expanded ? ' px-0' : '')}>
              {facility.name && (
                <div className="row">
                  <div className="col">
                    <h2 className="png-locations-details-name">
                      {expanded && <span>{facility.name}</span>}
                      {!expanded && <Link to={`/locations/${facility.id}`}>{facility.name}</Link>}
                    </h2>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-4">
                  <div className="container-fluid png-location-info">
                    {(facility.address || facility.email || facility.phone) && (
                      <>
                        {facility.address && (
                          <div className="row">
                            <div className="col">
                              <a href={facilityLink} target="_blank" rel="noopener noreferrer">
                                {facility.address.street && (
                                  <span>
                                    {facility.address.street}
                                    <br />
                                  </span>
                                )}
                                {facility.address.street2 && (
                                  <span>
                                    {facility.address.street2}
                                    <br />
                                  </span>
                                )}
                                {(facility.address.city ||
                                  facility.address.state ||
                                  facility.address.zip) && (
                                  <span>
                                    {facility.address.city ? facility.address.city + ', ' : ''}{' '}
                                    {facility.address.state ? facility.address.state + ' ' : ''}{' '}
                                    {facility.address.zip ? facility.address.zip : ''}
                                  </span>
                                )}
                              </a>
                            </div>
                          </div>
                        )}
                        {facility.email && (
                          <div className="row">
                            <div className="col">
                              <a href={`mailto:${facility.email}`}>{facility.email}</a>
                            </div>
                          </div>
                        )}
                        {facility.phone && (
                          <div className="row">
                            <div className="col">
                              <a href={`tel:${facility.phone}`}>
                                {formatPhone(String(facility.phone))}
                              </a>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {(facility.address || facility.email || facility.phone) && (
                      <div className="row png-location-icons">
                        {facility.email && (
                          <div className="col-3 text-left">
                            <div className="container-fluid px-0">
                              <div className="row">
                                <div className="col text-center pr-0">
                                  <a href={`mailto:${facility.email}`}>
                                    <FontAwesomeIcon icon="envelope" />
                                    <br />
                                    Email
                                  </a>
                                </div>
                                <div className="col w-100 p-0">&nbsp;</div>
                              </div>
                            </div>
                          </div>
                        )}
                        {facility.address && (
                          <div className="col-6 text-center">
                            <a href={facilityLink} target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon icon="map-marker-alt" />
                              <br />
                              Directions
                            </a>
                          </div>
                        )}
                        {facility.phone && (
                          <div className="col-3 text-right">
                            <div className="container-fluid px-0">
                              <div className="row">
                                <div className="col w-100 p-0">&nbsp;</div>
                                <div className="col text-center pl-0">
                                  <a href={`tel:${facility.phone}`}>
                                    <FontAwesomeIcon icon="mobile" />
                                    <br />
                                    Call
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <div className="row png-location-buttons">
                      <div className={`col-${expanded ? '12' : '7'} text-left png-location-button`}>
                        <button
                          type="button"
                          onClick={() => this.props.newReservation(facility.id)}
                          className="btn btn-primary btn-sm"
                          disabled={!this.props.status?.parking}
                        >
                          Reserve
                        </button>
                      </div>
                      {!expanded && (
                        <div className="col-5 text-right png-location-button">
                          <button
                            type="button"
                            onClick={() => this.props.facilityDetails(facility.id)}
                            className="btn btn-secondary btn-sm"
                          >
                            Details
                          </button>
                        </div>
                      )}
                    </div>
                    {!expanded && facility.about && (
                      <div className="row">
                        <div className="col png-location-info-about">
                          <p>{facility.about}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md">
                  <div className="container-fluid px-0">
                    {FacilityMap && (
                      <FacilityMap
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}`}
                        loadingElement={<div />}
                        containerElement={<div style={{ height: '350px', width: '100%' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                        pointsOfInterest={poi}
                        onMapLoad={() => {}}
                        onMapClick={() => {}}
                        onMarkerRightClick={() => {}}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {expanded && (
              <div>
                <Content name={`locations.${facility.code}.details`} showError={false} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // test mode?
  testMode: state.testMode.enabled || localStorage.getItem('testMode'),

  // status
  status: state.context.status,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // fetch a single facility
  fetchById: (facilityId, test) => {
    return dispatch(fetchFacility(facilityId, test)).catch((e) => {
      // log it
      console.error('Error fetching facility by ID: ' + facilityId, e);
    });
  },

  // create reservation at facility
  newReservation: (facilityId) => {
    dispatch(navigate('newReservation', facilityId));
  },

  // goes to a facility's details page
  facilityDetails: (facilityId) => {
    dispatch(navigate('locationDetails', facilityId));
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationDetails));
