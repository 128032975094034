import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { send as sendSOS } from './util/SOS/actions';

import { BasePureComponent } from 'components/Base';
import broken from './images/broken.png';

/**
 * A boundary for handling errors that no one else handled.
 */
class ErrorBoundary extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // keep track of errors
    this.state = {
      ...this.state,
      error: null,
    };
  }

  componentDidCatch(error, info) {
    // parent, for lifecycle logging
    super.componentDidCatch(error, info);

    // log the error
    console.error('Unhandled error caught', error);

    // flag that we have an error
    this.setState({ error: error });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    if (this.state.error) {
      // send an SOS for this one
      this.props.sendSOS(this.state.error);

      return (
        <div
          style={{
            display: 'table',
            width: '100%',
            height: '90vh',
            padding: '50px',
          }}
        >
          <section
            style={{
              display: 'table-row',
            }}
          >
            <div
              className="align-middle text-center"
              style={{
                display: 'table-cell',
                maxWidth: '400px',
              }}
            >
              <h2>It's broken. We're working on it!</h2>
              <p>
                <img src={broken} alt="It's broken" width="360" height="315" />
              </p>
              <p>
                This is embarrassing. We've already mobilized the repair crew. Check back shortly
                for a fix.
              </p>
            </div>
          </section>
        </div>
      );
    }
    return this.props.children;
  }
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // enable test mode
  sendSOS: (error) => {
    dispatch(sendSOS('Error boundary invoked', error));
  },
});

// make router props accessible; this is necessary to
// drive re-renders based on path changes
ErrorBoundary = withRouter(connect(null, mapDispatchToProps)(ErrorBoundary));

// set prop types and required-ness
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
};

export default ErrorBoundary;
