import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, reduxForm, formValueSelector, clearSubmitErrors, change } from 'redux-form';
import moment from 'moment/moment';
import $ from 'jquery';

import { BaseForm } from 'components/Form';
import { DateRange } from 'components/DateRange';
import { checkValidity } from 'components/Form/utility';
import './styles.scss';

/* Check-in/out dates form. */
class DatesForm extends BaseForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // to track when the user has chosen dates
    this.state = {
      ...this.state,
      datesApplied: false,
    };

    // minimum date/time is the quarter hour immediately preceding 75 minutes from now
    var min = moment().add(75, 'minutes');
    if (min.minute() >= 1 && min.minute() <= 14) {
      min.minute(0);
    } else if (min.minute() >= 15 && min.minute() <= 29) {
      min.minute(15);
    } else if (min.minute() >= 30 && min.minute() <= 44) {
      min.minute(30);
    } else {
      min.minute(45);
    }

    // do we already have a date/time set?
    let start;
    if (props.startMoment) {
      start = props.startMoment;

      // if it's earlier than the calculated min, use this as the new min
      if (start.isBefore(min)) {
        min = moment(start);
      }
    }

    // do we already have a date/time set?
    let end;
    if (props.startMoment && props.endMoment) {
      end = props.endMoment;

      // it's valid at this point
      this.state = {
        ...this.state,
        datesApplied: true,
      };
    }

    // for date management
    this.state = {
      ...this.state,
      minMoment: min,
      startMoment: start,
      endMoment: end,
    };

    // no form fields, so we're valid by default
    this.state = {
      ...this.state,
      htmlValid: true,
    };

    // to track when calendar is shown
    this.state = {
      ...this.state,
      calendarShown: false,
    };
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // if not an edit, set the initial date; we can't do this via redux-form's
    // 'initialValues' because it will overwrite current form values
    if (!this.props.original && this.state.startMoment) {
      this.props.dispatch(change(this.props.form, 'start', this.state.startMoment.unix()));
    }

    // attach handlers for the date picker
    $('input[name="datetimes"]').on('show.daterangepicker', () => {
      // disable the "next" button while the calendar is shown
      this.setState({ calendarShown: true });
    });
    $('input[name="datetimes"]').on('hide.daterangepicker', () => {
      // enable the "next" button when the calendar is hidden
      this.setState({ calendarShown: false });
    });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // date format
    var format = 'dddd, LL';
    if ($(window).width() < 450) {
      format = 'ddd, M/D';
    } else if ($(window).width() < 800) {
      format = 'ddd, l';
    }

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* date range */}
        <div className="form-row">
          <div className="form-group col-md has-error">
            <label className="col-form-label col-form-label-lg">Dates</label>
            <DateRange
              format={format}
              form={this.props.form}
              startMoment={this.state.startMoment}
              endMoment={this.state.endMoment}
              minMoment={this.state.minMoment}
              onApply={(start, end) => {
                // rather arbitrarily, set the start time to 6 AM and the end time to 5 PM;
                // these are just defaults, and the user will be able to change them
                start.hour(6);
                end.hour(17);
                start.minute(0);
                end.minute(0);
                start.second(0);
                end.second(0);
                start.millisecond(0);
                end.millisecond(0);

                // update the state
                this.setState({ datesApplied: true, startMoment: start, endMoment: end });

                // reset any FPP points
                this.props.dispatch(change(this.props.form, 'fppRedeemed', 0));

                // update the form
                this.props.dispatch(change(this.props.form, 'start', start.unix()));
                this.props.dispatch(change(this.props.form, 'end', end.unix()));
              }}
            />
            {this.props.dateTimeError && (
              <span className="png-field-error">{this.props.dateTimeError}</span>
            )}
          </div>
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            {!this.props.original && (
              <button
                type="button"
                onClick={() => this.props.onPrevious()}
                className="btn btn-primary btn-lg png-reservation-book-previous"
              >
                Back
              </button>
            )}
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.state.calendarShown ||
                !this.state.datesApplied ||
                this.props.dateTimeError ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
DatesForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },

  // validate the date
  validate: (values) => {
    const errors = {};

    // make sure the end date is equal to or after the start date
    if (values.start && values.end && moment.unix(values.end).isBefore(moment.unix(values.start))) {
      errors.end = 'Check out cannot be before check in';
    }

    return errors;
  },
})(DatesForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // drop-off date/time, if already set and if not an edit
  startMoment: ownProps.original
    ? moment.unix(ownProps.original.start)
    : formValueSelector(ownProps.form)(state, 'start')
    ? moment.unix(formValueSelector(ownProps.form)(state, 'start'))
    : null,

  // pick-up date/time, if already set and if not an edit
  endMoment: ownProps.original
    ? moment.unix(ownProps.original.end)
    : formValueSelector(ownProps.form)(state, 'end')
    ? moment.unix(formValueSelector(ownProps.form)(state, 'end'))
    : null,

  // date/time errors
  dateTimeError:
    state.form.reservationForm && state.form.reservationForm.syncErrors
      ? state.form.reservationForm.syncErrors.start || state.form.reservationForm.syncErrors.end
      : null,
});

// turn this into a container component
DatesForm = withRouter(connect(mapStateToProps, null)(DatesForm));

// set default props
DatesForm.defaultProps = {
  form: 'reservationForm',
};

export default DatesForm;
