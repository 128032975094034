import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import { context } from './util/Context/reducer';
import { alerts } from './components/Alerts/reducer';
import { busy } from './components/Busy/reducer';
import { menu } from './components/Menu/reducer';
import { navigate } from './components/Navigate/reducer';
import { testMode } from './components/TestMode/reducer';
import * as fpModal from './components/FPModal/reducers';
import * as customers from './scenes/Customers/reducers';
import * as reservations from './scenes/Reservations/reducers';

// create a root reducer
const rootReducer = (history) => {
  // combine all application reducers
  const appReducers = combineReducers({
    context: context,
    alerts: alerts,
    busy: busy,
    menu: menu,
    navigate: navigate,
    testMode: testMode,
    fpp: fpModal.reducers,
    form: reduxFormReducer.plugin({
      ...fpModal.formReducers,
      ...customers.formReducers,
      ...reservations.formReducers,
    }),
    router: connectRouter(history),
  });

  // wrap them in a root reducer
  return (state, action) => {
    // run standard reducers
    const newState = appReducers(state, action);

    // enable for logging
    if (false) {
      console.debug('Ran action', action, 'against state', state, 'and got new state', newState);
    }

    // return the new state
    return newState;
  };
};

export default rootReducer;
