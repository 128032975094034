import $ from 'jquery';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { initialize, reset, SubmissionError } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { BasePureComponent } from 'components/Base';
import { hideBusy, showBusy } from 'components/Busy/actions';
import FPCard from 'components/FPCard';
import { go as navigate } from 'components/Navigate/actions';
import { findCustomer, findFpNumber, loginViaOAuth2, logout } from './actions';
import FPForm from './components/FPForm';
import './styles.scss';

/* Frequent parker card. */
class FPModal extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // let's see if this is an OAuth2 callback
    if (props.location) {
      const params = new URLSearchParams(props.location.hash);
      const state = params.get('#state');
      if ('fbLogin' === state) {
        this.props.login({
          provider: 'facebook',
          token: params.get('access_token'),
          viaCallback: true,
        });

        // clear the params so the user can refresh
        props.history.replace(props.location.pathname);
      }
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // are we logged in?
    var loggedIn = this.props.customer;

    return (
      <div>
        <div
          className="modal fade"
          id="png-fp-card"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="png-fp-card-title"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header png-fp-card-header png-background">
                <h2 className="modal-title" id="png-fp-card-title">
                  FP Card
                </h2>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {loggedIn && (
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <FPCard customer={this.props.customer} />
                    </div>

                    {/* logout */}
                    <div className="row">
                      <div className="col text-center">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm png-fp-button-logout"
                          onClick={this.props.logout}
                        >
                          Logout
                        </button>
                      </div>
                    </div>

                    {/* edit profile */}
                    <div className="row">
                      <div className="col text-center">
                        <p className="mt-3 mb-0">
                          <small>
                            <Link
                              to="/profile"
                              onClick={() => {
                                // dismiss modal
                                $('#png-fp-card').modal('hide');
                              }}
                            >
                              Edit your profile
                            </Link>
                          </small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="png-fp-login"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="png-fp-login-title"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content png-fp-login">
              <div className="modal-header png-fp-login-header">
                <h2 className="modal-title" id="png-fp-login-title">
                  Login
                </h2>
                <button
                  type="submit"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.reset}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <FPForm
                  facilities={this.props.facilities}
                  onSubmit={this.props.login}
                  onForgotFP={this.props.findFpNumber}
                />

                {/* sign up */}
                <div className="form-row">
                  <div className="col text-center">
                    <small>
                      No account?{' '}
                      <Link
                        to="/profile"
                        onClick={() => {
                          // dismiss modal
                          $('#png-fp-login').modal('hide');
                        }}
                      >
                        Sign up!
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // customer
    customer: state.context.customer,

    // facilities
    facilities: state.context.facilities,

    // the router, for redirections
    router: state.router,
  };
}

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // viewProfile
  viewProfile: () => {
    // dismiss modal
    $('#png-fp-card').modal('hide');

    // navigate to profile
    dispatch(navigate('viewProfile'));
  },

  // find a customer's FP number
  findFpNumber: (values) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // destructure the values
    const { facilityId, email } = values;

    // find the customer
    return dispatch(findFpNumber(facilityId, email))
      .then(() => {
        // tell the customer that we've emailed them
        dispatch(
          addAlert(
            'success',
            'If you have an account, we have emailed your Frequent Parker # to you!',
            8000,
          ),
        );
      })
      .catch((e) => {
        // log it
        console.error('Error searching for customer', e);

        // tell the user
        const message = 'We ran into a problem! Please try again.';
        throw new SubmissionError({
          _error: message,
        });
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // login with FP# or via OAuth
  login: (values) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // destructure the values
    const { facilityId, email, fpNumber, provider, token, viaCallback } = values;

    // what type of login is it?
    const isOAuth2 = provider && token;
    var doLogin;
    if (isOAuth2) {
      doLogin = () => {
        return loginViaOAuth2(provider, token);
      };
    } else {
      // FP should always be upper-case
      doLogin = () => {
        return findCustomer(facilityId, email, fpNumber.toUpperCase());
      };
    }

    // do the login
    return dispatch(doLogin())
      .then((customer) => {
        // if success, dismiss modal and show a success message
        if (customer) {
          var subMessage = 'Now go earn some points!';
          if (customer.fpp && customer.fpp > 0) {
            subMessage =
              'You have ' +
              customer.fpp +
              ' point' +
              (customer.fpp !== 1 ? 's' : '') +
              '. Now go earn some more!';
          } else if (!customer.fpp || customer.fpp === 0) {
            subMessage = "You don't have any points yet. Go earn some!";
          }

          // dismiss modal and fire message
          $('#png-fp-login').modal('hide');
          dispatch(addAlert('success', 'You have been logged in. ' + subMessage, 5000));

          // reset the profile form
          dispatch(reset('customerForm'));
        }
      })
      .catch((e) => {
        // is this a "customer not found" error?
        if (e.code && e.code === 7000) {
          // tell the user
          const message = isOAuth2
            ? `We couldn't find a Frequent Parker account linked to your ${provider.replace(
                /^\w/,
                (c) => c.toUpperCase(),
              )} account! ` +
              "You'll first need to login using your frequent parker # and then link it."
            : "We couldn't find your account! Please review your information and try again. Is your primary location correct?";
          if (viaCallback) {
            dispatch(addAlert('error', message, 8000));
            return;
          } else {
            throw new SubmissionError({
              _error: message,
            });
          }
        }

        // log it
        console.error('Error searching for customer', e);

        // tell the user
        const message = 'We ran into a problem! Please try again.';
        if (viaCallback) {
          dispatch(addAlert('error', message, 4000));
          return;
        } else {
          throw new SubmissionError({
            _error: message,
          });
        }
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // logout
  logout: () => {
    return dispatch(logout()).finally(() => {
      // dismiss modal
      $('#png-fp-card').modal('hide');

      // reset a few forms; this will force the respective pages to re-render
      dispatch(reset('fpForm'));
      dispatch(reset('customerForm'));
      dispatch(reset('corporateInquiryForm'));

      // ... and the corporate inquiry form
      dispatch(
        initialize(
          'corporateInquiryForm',
          {},
          {
            keepDirty: false,
            updateUnregisteredFields: true,
            keepValues: false,
          },
        ),
      );
    });
  },

  // reset
  reset: () => {
    // reset the form
    dispatch(reset('fpForm'));
  },
});

// turn this presentation component into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FPModal));
