import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, reduxForm, clearSubmitErrors } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import ReservationDetails from 'scenes/Reservations/components/ReservationDetails';

/* Confirm form. */
class ConfirmForm extends BaseForm {
  constructor(props) {
    // parent
    super(props);

    // no form fields, so we're valid by default
    this.state = {
      ...this.state,
      htmlValid: true,
    };
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* details */}
        <ReservationDetails reservation={this.props.currentValues} narrow={true} />

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
ConfirmForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(ConfirmForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // current form values
  currentValues: state.form.reservationForm.values,
});

// turn this into a container component
ConfirmForm = withRouter(connect(mapStateToProps, null)(ConfirmForm));

// set default props
ConfirmForm.defaultProps = {
  form: 'reservationForm',
};

export default ConfirmForm;
