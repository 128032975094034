// action types
export const GO = 'navigate/GO';
export const CLEAR = 'navigate/CLEAR';

// navigate somewhere
export const go = (destination, supplemental = null) => ({
  type: GO,
  destination: destination,
  supplemental: supplemental ? String(supplemental) : null,
});

// clear navigation
export const clear = () => ({
  type: CLEAR,
});
