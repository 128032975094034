/**
 * Fetches a piece of content by name.
 */
export function fetch(name) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_CONTENT',
      payload: {
        request: {
          method: 'get',
          url: `/content/${name}`,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}
