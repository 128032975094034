// booking flow steps
export const STEPS = Object.freeze({
  LOCATION: 1,
  DATES: 2,
  TIMES: 3,
  SERVICES: 4,
  PACKAGES: 5,
  CUSTOMER: 6,
  VEHICLE: 7,
  PAY: 8,
  CONFIRM: 9,
});
