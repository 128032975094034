/**
 * Fetches a reservation by facility, customer email, and ID.
 */
export function fetch(facilityId, email, reservationId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_RESERVATION',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/customers/${email}/reservations/${reservationId}`,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Fetches all reservations by customer.
 */
export function fetchAll(email, lastName) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_RESERVATION',
      payload: {
        request: {
          method: 'get',
          url: `/customers/${email}/reservations?customerLastName=${lastName}`,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Requests a receipt resend for a reservation.
 */
export function resendReceipt(facilityId, email, reservationId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'RESEND_RESERVATION_RECEIPT',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/customers/${email}/reservations/${reservationId}/receipt`,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Cancels a reservation.
 */
export function cancel(facilityId, email, reservationId) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'CANCEL_RESERVATION',
      payload: {
        request: {
          method: 'put',
          url: `/facilities/${facilityId}/customers/${email}/reservations/${reservationId}/cancel`,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Adds a new reservation.
 */
export function add(facilityId, reservation) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'ADD_RESERVATION',
      payload: {
        request: {
          method: 'post',
          url: `/facilities/${facilityId}/customers/${reservation.customer.email}/reservations`,
          data: reservation,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}

/**
 * Updates an existing reservation.
 */
export function update(facilityId, reservation) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'UPDATE_RESERVATION',
      payload: {
        request: {
          method: 'put',
          url: `/facilities/${facilityId}/customers/${reservation.customer.email}/reservations/${reservation.id}`,
          data: reservation,
        },
      },
    }).then((response) => {
      return response;
    });
  };
}
