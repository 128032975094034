import moment from 'moment/moment';
import { pick as dotPick } from 'dot-object';

// clones an object
export function clone(o) {
  if (o) {
    return JSON.parse(JSON.stringify(o));
  } else {
    return o;
  }
}

// scrolls to the top
export function scrollToTop() {
  window.scrollTo(0, 0);
}

/**
 * Gets the current date as a moment. Useful for mocking
 * dates for testing.
 */
export function currentDate(excludeWeekends = false) {
  // note: we never mock staging or prod
  let date = moment();
  if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'qa') {
    // change this one for testing (for example, moment('2019-05-04'))
    date = moment();
  }

  // if not including weekends, move backward to last weekday
  if (excludeWeekends) {
    if (date.day() === 6) {
      date.subtract(1, 'days');
    } else if (date.day() === 0) {
      date.subtract(2, 'days');
    }
  }

  return date;
}

// Google map pin
export function getMapPin() {
  return {
    path: 'M 10.727 3.658 L 9.226 6.699 C 8.789 7.584 7.945 8.198 6.967 8.34 L 3.612 8.827 L 6.04 11.194 C 6.747 11.883 7.07 12.876 6.903 13.849 L 6.33 17.191 L 9.331 15.614 C 10.205 15.154 11.249 15.154 12.123 15.614 L 15.124 17.191 L 14.551 13.849 C 14.384 12.876 14.706 11.883 15.414 11.194 L 17.842 8.827 L 14.486 8.34 C 13.509 8.198 12.664 7.584 12.227 6.699 L 10.727 3.658 Z M 11.623 0.956 C 11.257 0.213 10.197 0.213 9.83 0.956 L 7.433 5.814 C 7.287 6.109 7.005 6.313 6.68 6.361 L 1.319 7.14 C 0.499 7.259 0.172 8.267 0.765 8.845 L 4.644 12.626 C 4.88 12.856 4.987 13.187 4.932 13.511 L 4.016 18.85 C 3.876 19.667 4.733 20.29 5.467 19.904 L 10.261 17.384 C 10.553 17.231 10.901 17.231 11.192 17.384 L 15.987 19.904 C 16.72 20.29 17.578 19.667 17.438 18.85 L 16.522 13.511 C 16.466 13.187 16.574 12.856 16.81 12.626 L 20.688 8.845 C 21.282 8.267 20.954 7.259 20.134 7.14 L 14.774 6.361 C 14.448 6.313 14.166 6.109 14.021 5.814 L 11.623 0.956 Z',
    strokeColor: 'black',
    fillColor: '#e6cb28',
    fillOpacity: 1,
    scaledSize: { width: 30, height: 30 },
    anchor: { x: 15, y: 15 },
  };
}

// Google map styles
export function getMapStyles(spartan) {
  // convenience function to control feature display
  const displayFeature = (feature, labels) => [
    {
      featureType: feature,
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: feature,
      elementType: 'labels.text',
      stylers: [
        {
          visibility: labels ? 'on' : 'off',
        },
      ],
    },
  ];

  return spartan
    ? [
        // turn everything off by default
        {
          featureType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },

        // and turn just a few things back on
        ...displayFeature('water', false),
        ...displayFeature('administrative.country', false),
        ...displayFeature('administrative.province', false),
      ]
    : [
        // turn everything off by default
        {
          featureType: 'all',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },

        // and turn just a few things back on
        ...displayFeature('water', false),
        ...displayFeature('administrative.country', false),
        ...displayFeature('administrative.province', false),
        ...displayFeature('poi.park', false),
        ...displayFeature('road', false),
        ...displayFeature('road.highway', true),
        ...displayFeature('transit.station.airport', true),
      ];
}

// sorts an array in place
export function sortArray(a, field = null, asc = true, caseSensitive = false) {
  if (a && Array.isArray(a)) {
    a.sort(function (a, b) {
      let x = field ? dotPick(field, a) : a;
      let y = field ? dotPick(field, b) : b;
      if (typeof x === 'number') {
        return asc ? x - y : y - x;
      } else if (typeof x === 'boolean') {
        if (x && y) {
          return 0;
        } else if (!x && !y) {
          return 0;
        } else if (x && !y) {
          return asc ? 1 : -1;
        } else if (!x && y) {
          return asc ? -1 : 1;
        } else {
          return 0;
        }
      } else if (typeof x === 'string') {
        if (!caseSensitive) {
          x = x ? x.toLowerCase() : x;
          y = y ? y.toLowerCase() : y;
        }
        if (x && y && x.length > 0 && y.length > 0) {
          if (x < y) {
            return asc ? -1 : 1;
          } else if (x > y) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        } else if (!x) {
          return asc ? -1 : 1;
        } else if (!y) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      } else {
        if (x && y) {
          if (x < y) {
            return asc ? -1 : 1;
          } else if (x > y) {
            return asc ? 1 : -1;
          } else {
            return 0;
          }
        } else if (!x) {
          return asc ? -1 : 1;
        } else if (!y) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      }
    });
  }
}
