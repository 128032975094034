import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* Some verbiage and an image talking about shuttle service. */
export class Shuttles extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col png-page-header">Complimentary Shuttles, 24x7</div>
        </div>
        <div className="row png-shuttles-picture">
          <div className="col">
            <img
              src={this.props.backgroundImage}
              alt="Complimentary shuttles"
              style={{ maxWidth: '100%', maxHeight: '100vh', margin: 'auto' }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="png-content-content mt-2">
              Our team of shuttle drivers are ready to serve travelers and offer assistance with
              luggage.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// set prop types and required-ness
Shuttles.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
};

// set defaults
Shuttles.defaultProps = {
  backgroundImage: 'https://assets.bookparkngo.com/home/shuttles.png',
};

// turn this into a container component
export default withRouter(connect(null, null)(Shuttles));
