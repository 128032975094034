/**
 * Sends an email.
 */
export function send(emailKey, content, challengeResponse = null) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'SEND_EMAIL',
      payload: {
        request: {
          method: 'post',
          url:
            `/email?emailKey=${emailKey}` +
            (challengeResponse ? `&challengeResponse=${challengeResponse}` : ''),
          data: content,
        },
      },
    });
  };
}
