import React from 'react';
import { connect } from 'react-redux';

import { BasePureComponent } from 'components/Base';
import { currentDate } from 'util/index';
import './styles.scss';

/* The application's footer. */
class Footer extends BasePureComponent {
  render() {
    // parent
    super.render();

    // render
    return (
      <div className="container-fluid png-footer png-background d-print-none">
        <div className="no-gutters row png-footer-container">
          <div className="col text-right png-footer-links">
            Copyright &copy; {currentDate().year()} Park 'N Go
          </div>
        </div>
      </div>
    );
  }
}

// turn this into a container component
Footer = connect()(Footer);

// set prop types and required-ness
Footer.propTypes = {};

// set default props
Footer.defaultProps = {};

export default Footer;
