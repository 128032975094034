import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap-daterangepicker/daterangepicker.css';
import $ from 'jquery';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import { isTouchDevice } from 'util/form/utility';
import './styles.scss';

/* Date range selector for inclusion in a form. Wrapper around exernal component. */
export class DateRange extends BasePureComponent {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for input field management
    this.state = {
      ...this.state,
      datesApplied: props.startMoment && props.endMoment,
    };
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="input-group">
        <DateRangePicker
          initialSettings={{
            opens: 'center',
            showDropdowns: false,
            timePicker: this.props.showTime,
            timePickerIncrement: 15,
            maxSpan: {
              days: 90,
            },
            showCustomRangeLabel: false,
            startDate: this.props.startMoment,
            endDate: this.props.endMoment,
            minDate: this.props.minMoment,
            locale: {
              format: this.props.format,
            },
          }}
          onEvent={(event, picker) => {
            // capture 'this'
            const self = this;

            // if it's a "show" event, we do a few things
            if (event.type === 'show') {
              // do not close the picker when clicking outside
              picker.outsideClick = () => {};

              // insert labels into the calendar when the view is updated
              if (!picker._updateCalendarsWrapped) {
                // capture the original updateCalendars
                const updateCalendars = picker.updateCalendars;

                // wrap the original updateCalendars
                picker.updateCalendars = function () {
                  // call the original updateCalendars
                  updateCalendars.apply(this, arguments);

                  // if not already there, insert labels before time selectors
                  setTimeout(() => {
                    const calendarTimes = $('.calendar-time');
                    if (calendarTimes.length % 2 === 0) {
                      for (let i = 0; i < calendarTimes.length; i++) {
                        // if odd, it's a check-in time
                        if (i % 2 === 0) {
                          // only if it doesn't already have a label
                          if ($(calendarTimes[i]).find('.png-time-label').length === 0) {
                            $(calendarTimes[i]).prepend(
                              '<span class="png-time-label">Check in:&nbsp;</span>',
                            );
                          }
                        } else {
                          // only if it doesn't already have a label
                          if ($(calendarTimes[i]).find('.png-time-label').length === 0) {
                            $(calendarTimes[i]).prepend(
                              '<span class="png-time-label">Check out:&nbsp;</span>',
                            );
                          }
                        }
                      }
                    }
                  }, 0);
                };
                picker._updateCalendarsWrapped = true;
              }

              // update the calendar hint as the start date is selected
              if (!picker._setStartDateWrapped) {
                // capture the original setStartDate
                const setStartDate = picker.setStartDate;

                // wrap the original setStartDate
                picker.setStartDate = function () {
                  // call the original setStartDate
                  setStartDate.apply(this, arguments);

                  // update the calendar hint
                  const calendarHint = $('.png-calendar-hint');
                  if (calendarHint.length > 0) {
                    calendarHint.html(
                      '<div class="png-calendar-hint">Select a check out date</div>',
                    );
                  }
                };
                picker._setStartDateWrapped = true;
              }

              // update the calendar hint as the end date is selected
              if (!picker._setEndDateWrapped) {
                // capture the original setEndDate
                const setEndDate = picker.setEndDate;

                // wrap the original setEndDate
                picker.setEndDate = function () {
                  // call the original setEndDate
                  setEndDate.apply(this, arguments);

                  // update the calendar hint
                  const calendarHint = $('.png-calendar-hint');
                  if (calendarHint.length > 0) {
                    if (self.props.showTime) {
                      calendarHint.html('<div class="png-calendar-hint">Select times</div>');
                    } else {
                      calendarHint.html(
                        `<div class="png-calendar-hint">Select 'Apply' to confirm</div>`,
                      );
                    }
                  }
                };
                picker._setEndDateWrapped = true;
              }

              // insert calendar hint and time labels
              if (!picker._calendarHintAndLabels) {
                const pickers = $('.daterangepicker');
                for (let index = 0; index < pickers.length; index++) {
                  // get the picker
                  const picker = pickers[index];

                  // get the calendars within the picker
                  const calendars = $(picker).find('.drp-calendar');

                  // if not already wrapped...
                  if (
                    calendars.length > 0 &&
                    calendars.parent &&
                    !$(calendars.parent()).hasClass('png-calendar-container')
                  ) {
                    // ... wrap them in a container
                    calendars.wrapAll('<div class="png-calendar-container"></div>');

                    // add a <span> into the container for the hints
                    $(picker)
                      .find('.png-calendar-container')
                      .prepend('<div class="png-calendar-hint">Select a check in date</div>');
                  }
                }

                // insert labels before time selectors
                const calendarTimes = $('.calendar-time');
                if (calendarTimes.length % 2 === 0) {
                  for (let i = 0; i < calendarTimes.length; i++) {
                    // if odd, it's a check-in time
                    if (i % 2 === 0) {
                      $(calendarTimes[i]).prepend(
                        '<span class="png-time-label">In time:&nbsp;</span>',
                      );
                    } else {
                      $(calendarTimes[i]).prepend(
                        '<span class="png-time-label">Out time:&nbsp;</span>',
                      );
                    }
                  }
                }
                // only do this once
                picker._calendarHintAndLabels = true;
              }
            }
          }}
          onCallback={(start, end) => {
            // invoke callback
            this.props.onApply(start, end);

            // note that we've got dates now
            this.setState({ datesApplied: true });
          }}
        >
          <input
            type="text"
            title={this.props.placeholder}
            name="datetimes"
            value={this.state.datesApplied ? undefined : this.props.placeholder || ''}
            className={
              this.props.className ||
              `form-control form-control${isTouchDevice() ? '-sm' : '-lg'} png-bookbox-dates`
            }
            placeholder={this.props.placeholder}
            tooltip={this.props.tooltip}
            required={true}
            readOnly={true}
          />
        </DateRangePicker>
        {isTouchDevice() && this.props.tooltip && (
          <div className="input-group-append">
            <span className="input-group-text">
              <div className="png-tooltip">
                <FontAwesomeIcon icon="question" />
                <span className="png-tooltip-text">{this.props.tooltip}</span>
              </div>
            </span>
          </div>
        )}
      </div>
    );
  }
}

// turn this into a container component
DateRange = withRouter(connect(null, null)(DateRange));

// set prop types and required-ness
DateRange.propTypes = {
  form: PropTypes.string.isRequired,
  showTime: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  startMoment: PropTypes.instanceOf(moment),
  endMoment: PropTypes.instanceOf(moment),
  minMoment: PropTypes.instanceOf(moment).isRequired,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
};

// set default props
DateRange.defaultProps = {
  showTime: false,
  placeholder: 'When will you be parking?',
  tooltip: `The duration for which you will be parked at the lot, in the lot's time zone`,
};

export default DateRange;
