import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import BasePage from 'components/Page';
import Content from 'components/Content';
import LocationDetails from './components/LocationDetails';

/* Locations summary page. */
class Locations extends BasePage {
  render() {
    // parent, for lifecycle logging
    super.render();

    // sort the facilities by name
    if (this.props.facilities) {
      this.props.facilities.sort(function (a, b) {
        return a.name > b.name;
      });
    }

    // do we have a single location?
    return (
      <div>
        {!this.props.facilities && (
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h2>Unavailable</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>
                  Location details are temporarily unavailable. Rest assured that we are working
                  hard to resolve this problem. Please check back later!
                </span>
              </div>
            </div>
          </div>
        )}

        {this.props.facilities && (
          <div className="container-fluid">
            <div className="row">
              <div className="col png-page-header">Locations</div>
            </div>
            <div className="row">
              <div className="col">
                <Content name="locationsSummary" showError={false} />
              </div>
            </div>
            {this.props.facilities.map((facility) => (
              <div key={facility.id}>
                <div className="row">
                  <div className="col">
                    <LocationDetails facilityId={facility.id} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // facilities
  facilities: state.context.facilities,
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, null)(Locations));
