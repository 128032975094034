/**
 * Gets a facility Id by netPark code.
 */
export function getIdByNetParkCode(facilities, code) {
  // look for the facility with the matching code
  for (var i = 0; i < facilities.length; i++) {
    if (facilities[i].netParkCode === Number(code)) {
      return facilities[i].id;
    }
  }

  // special case for sandbox
  if (Number(code) === 2147483647) {
    return 2147483647;
  }

  // we should never get here
  return -1;
}

/**
 * Gets a facility Id by facility code.
 */
export function getIdByFacilityCode(facilities, code) {
  // look for the facility with the matching code
  for (var i = 0; i < facilities.length; i++) {
    if (facilities[i].code === code) {
      return facilities[i].id;
    }
  }

  // special case for sandbox
  if (code === 'TST') {
    return 2147483647;
  }

  // we should never get here
  return -1;
}

/**
 * Gets a facility netPark code by Id.
 */
export function getNetParkCodeById(facilities, id) {
  // look for the facility with the matching code
  for (var i = 0; i < facilities.length; i++) {
    if (facilities[i].netParkCode === id) {
      return facilities[i].netParkCode;
    }
  }

  // special case for sandbox
  if (id === 2147483647 || id === '2147483647') {
    return 2147483647;
  }

  // we should never get here
  return -1;
}

/**
 * Gets a facility name by Id.
 */
export function getFacilityNameById(facilities, id) {
  // look for the facility with the matching code
  for (var i = 0; i < facilities.length; i++) {
    if (facilities[i].id === id) {
      return facilities[i].name;
    }
  }

  // special case for sandbox
  if (id === 2147483647 || id === '2147483647') {
    return 'Sandbox';
  }

  // we should never get here
  return '';
}
