import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import './styles.scss';

/* A loading indicator. */
class Loading extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div className="png-loading">
        <div className="loader">
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
          <div className="loader--dot" />
        </div>
      </div>
    );
  }
}

// turn this into a container component
export default withRouter(connect()(Loading));
