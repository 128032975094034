/**
 * Fleshes out ancillary information on a customer.
 */
function fleshOut(customer) {
  if (customer && customer.oauth2) {
    // turn it into an object
    try {
      customer.linked = JSON.parse(customer.oauth2);
    } catch (e) {
      console.error("Error parsing customer's OAuth2 information: " + customer.oauth2, e);
    }
  }

  return customer;
}

/**
 * Looks an FP number up by facility and email. If found, emails the customer.
 * To prevent fishing, nothing is returned.
 */
export function fetchFpNumber(facilityId, email) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_FP_NUMBER',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/customers/${email}/alternateId`,
        },
      },
    });
  };
}

/**
 * Fetches a customer by facility, email, and FP number.
 */
export function fetch(facilityId, email, fpNumber) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_CUSTOMER',
      payload: {
        request: {
          method: 'get',
          url: `/facilities/${facilityId}/customers/${email}?alternateCustomerId=${fpNumber}`,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Fetches a customer by OAuth2 token.
 */
export function fetchByOAuth2Token(provider, token) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'FETCH_CUSTOMER_BY_OAUTH',
      payload: {
        request: {
          method: 'get',
          url: `/customers/oauth/${provider}/${token}`,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Links a customer to an OAuth2 provider.
 */
export function linkToOAuth2(provider, token, customer) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'LINK_CUSTOMER_TO_OAUTH',
      payload: {
        request: {
          method: 'post',
          url: `/customers/oauth/${provider}/${token}`,
          data: customer,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Unlinks a customer to an OAuth2 provider.
 */
export function unlinkFromOAuth2(provider, token, customer) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'UNLINK_CUSTOMER_TO_OAUTH',
      payload: {
        request: {
          method: 'delete',
          url: `/customers/oauth/${provider}/${token}`,
          data: customer,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Adds a new customer.
 */
export function add(facilityId, customer, challengeResponse = null) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'ADD_CUSTOMER',
      payload: {
        request: {
          method: 'post',
          url:
            `/facilities/${facilityId}/customers` +
            (challengeResponse ? `?challengeResponse=${challengeResponse}` : ''),
          data: customer,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Updates an existing customer.
 */
export function update(facilityId, customerId, customer, challengeResponse = null) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'UPDATE_CUSTOMER',
      payload: {
        request: {
          method: 'put',
          url:
            `/facilities/${facilityId}/customers/${customerId}` +
            (challengeResponse ? `?challengeResponse=${challengeResponse}` : ''),
          data: customer,
        },
      },
    }).then((response) => {
      return fleshOut(response);
    });
  };
}

/**
 * Deletes a customer.
 */
export function remove(facilityId, customerId, challengeResponse = null) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // make the call
    return dispatch({
      type: 'REMOVE_CUSTOMER',
      payload: {
        request: {
          method: 'delete',
          url:
            `/facilities/${facilityId}/customers/${customerId}` +
            (challengeResponse ? `?challengeResponse=${challengeResponse}` : ''),
        },
      },
    });
  };
}
