import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { push as ReactMenu } from 'react-burger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BasePureComponent } from 'components/Base';
import { open as openMenu, close as closeMenu } from 'components/Menu/actions';
import './styles.scss';

/* The application's menu. */
class Menu extends BasePureComponent {
  constructor(props) {
    super(props);

    // for location showing/hiding
    this.state = { showLocations: true };
  }

  onClick() {
    // close the menu
    this.props.closeMenu();
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // sort the facilities by name
    if (this.props.facilities) {
      this.props.facilities.sort(function (a, b) {
        return a.name > b.name;
      });
    }

    // render
    return (
      <div className="d-print-none">
        {/* ribbon menu */}
        <div id="png-ribbon" className="png-ribbon png-background">
          {/* home */}
          <div className="png-ribbon-item">
            <span className="png-ribbon-item-icon">
              <Link to="/" onClick={() => this.onClick()} title="Go to the Home page">
                <FontAwesomeIcon
                  icon="home"
                  className={this.props.path === '/' ? 'png-ribbon-item-icon-selected' : ''}
                />
              </Link>
            </span>
          </div>

          {/* new reservation */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div className="png-ribbon-item">
              <span className="png-ribbon-item-icon">
                <Link
                  to="/reservations/new"
                  onClick={() => this.onClick()}
                  title="Book a new reservation"
                >
                  <FontAwesomeIcon
                    icon="plus"
                    className={
                      this.props.path.startsWith('/reservations/new') ||
                      this.props.path.startsWith('/reservations/edit')
                        ? 'png-ribbon-item-icon-selected'
                        : ''
                    }
                  />
                </Link>
              </span>
            </div>
          )}

          {/* view reservations */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div className="png-ribbon-item">
              <span className="png-ribbon-item-icon">
                <Link
                  to="/reservations"
                  onClick={() => this.onClick()}
                  title="Find a previously booked reservation"
                >
                  <FontAwesomeIcon
                    icon="search"
                    className={
                      !(
                        this.props.path.startsWith('/reservations/new') ||
                        this.props.path.startsWith('/reservations/edit')
                      ) && this.props.path.startsWith('/reservations')
                        ? 'png-ribbon-item-icon-selected'
                        : ''
                    }
                  />
                </Link>
              </span>
            </div>
          )}

          {/* frequent parker */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div className="png-ribbon-item">
              <span className="png-ribbon-item-icon">
                <Link
                  to="/profile"
                  onClick={() => this.onClick()}
                  title={
                    this.props.loggedIn
                      ? 'View your Frequent Parker profile'
                      : 'Apply for the Frequent Parker program'
                  }
                >
                  <FontAwesomeIcon
                    icon="user"
                    className={
                      this.props.path.startsWith('/profile') ? 'png-ribbon-item-icon-selected' : ''
                    }
                  />
                </Link>
              </span>
            </div>
          )}

          {/* locations */}
          <div className="png-ribbon-item">
            <span className="png-ribbon-item-icon">
              <Link
                to="/locations"
                onClick={() => this.onClick()}
                title="View information about our locations"
              >
                <FontAwesomeIcon
                  icon="compass"
                  className={
                    this.props.path.startsWith('/locations') ? 'png-ribbon-item-icon-selected' : ''
                  }
                />
              </Link>
            </span>
          </div>
        </div>

        {/* flyout menu */}
        <ReactMenu
          width={300}
          pageWrapId="png-content"
          outerContainerId="png-app"
          isOpen={this.props.menuOpen}
          onStateChange={(state) => {
            // if we are opening, start with the locations collapsed
            if (state.isOpen) {
              this.setState({ showLocations: false });
            }

            // perfrom the change action
            this.props.menuChanged(state.isOpen);
          }}
        >
          {/* close menu */}
          <div>
            <div id="png-menu-close-container" className="png-menu-close-container">
              <div
                id="png-menu-close-icon"
                className="png-menu-close-icon"
                style={{ outline: 'none' }}
                tabIndex="0"
                aria-label="Menu"
                role="button"
                aria-controls="navigation"
                onClick={() => this.onClick()}
              >
                <div id="png-menu-close-bar1" className="png-menu-close-bar1" />
                <div id="png-menu-close-bar2" className="png-menu-close-bar2" />
              </div>
            </div>
          </div>

          {/* home */}
          <div>
            <div className="png-menu-primary">
              <span className="png-menu-primary-text">
                <Link to="/" onClick={() => this.onClick()} title="Go to the Home page">
                  Home
                </Link>
              </span>
            </div>
          </div>

          {/* new reservation */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div>
              <div className="png-menu-primary">
                <span className="png-menu-primary-text">
                  <Link
                    to="/reservations/new"
                    onClick={() => this.onClick()}
                    title="Book a new Reservation"
                  >
                    New Reservation
                  </Link>
                </span>
              </div>
            </div>
          )}

          {/* view reservations */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div>
              <div className="png-menu-primary">
                <span className="png-menu-primary-text">
                  <Link
                    to="/reservations"
                    onClick={() => this.onClick()}
                    title="Find a previously booked reservation"
                  >
                    Find Reservation
                  </Link>
                </span>
              </div>
            </div>
          )}

          {/* frequent parker */}
          {!this.props.status?.parking ? (
            <></>
          ) : (
            <div>
              <div className="png-menu-primary">
                <span className="png-menu-primary-text">
                  <Link
                    to="/profile"
                    onClick={() => this.onClick()}
                    title={
                      this.props.loggedIn
                        ? 'View your Frequent Parker profile'
                        : 'Apply for the Frequent Parker program'
                    }
                  >
                    Frequent Parker
                  </Link>
                </span>
              </div>
            </div>
          )}

          {/* locations */}
          <div>
            <div className="png-menu-primary">
              <span className="png-menu-primary-text">
                <Link
                  to="/locations"
                  onClick={() => this.onClick()}
                  title="View information about our locations"
                >
                  Locations
                </Link>
                <div
                  className="png-menu-primary-togglesubs"
                  title={this.state.showLocations ? 'Hide locations' : 'Show all locations'}
                >
                  <FontAwesomeIcon
                    icon={this.state.showLocations ? 'minus' : 'plus'}
                    onClick={() =>
                      this.setState({
                        showLocations: !this.state.showLocations,
                      })
                    }
                  />
                </div>
              </span>
            </div>
            {this.props.facilities && this.state.showLocations && (
              <div className="png-menu-primary-subs">
                {this.props.facilities.map((facility) => (
                  <div key={facility.id} className="png-menu-primary-sub">
                    <span className="png-menu-primary-sub-text">
                      <Link
                        to={`/locations/${facility.id}`}
                        onClick={() => this.onClick()}
                        title={`Get details about the ${facility.name} location`}
                      >
                        {facility.name}
                      </Link>
                    </span>
                  </div>
                ))}
                <div className="png-menu-more-locations">
                  <div className="png-menu-primary-sub">&bull; More Airport Sites</div>
                  <div className="png-menu-primary-sub">
                    <span className="png-menu-primary-sub-text">Baltimore</span>
                  </div>
                  <div className="png-menu-primary-sub">
                    <span className="png-menu-primary-sub-text">Miami</span>
                  </div>
                  <div className="png-menu-primary-sub">
                    <span className="png-menu-primary-sub-text">Omaha</span>
                  </div>
                  <div className="png-menu-primary-sub">
                    <span className="png-menu-primary-sub-text">Pittsburgh</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* spacer */}
          <div>
            <div className="png-menu-primary">&nbsp;</div>
          </div>

          {/* mobile apps */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link
                  to="/mobile"
                  onClick={() => this.onClick()}
                  title="Get information about our mobile apps"
                >
                  Mobile Apps
                </Link>
              </span>
            </div>
          </div>

          {/* corporate discount */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link
                  to="/corporateInquiry"
                  onClick={() => this.onClick()}
                  title="Inquire about our corporate discount"
                >
                  Corporate Discount
                </Link>
              </span>
            </div>
          </div>

          {/* about us */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link
                  to="/about"
                  onClick={() => this.onClick()}
                  title="Learn more about Park 'N Go"
                >
                  About Us
                </Link>
              </span>
            </div>
          </div>

          {/* refund policy */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link to="/refunds" onClick={() => this.onClick()} title="Review our refund policy">
                  Refund Policy
                </Link>
              </span>
            </div>
          </div>

          {/* terms and conditions */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link
                  to="/terms"
                  onClick={() => this.onClick()}
                  title="Read the terms & conditions for using the site"
                >
                  Terms & Conditions
                </Link>
              </span>
            </div>
          </div>

          {/* privacy policy */}
          <div>
            <div className="png-menu-secondary">
              <span className="png-menu-primary-text">
                <Link
                  to="/privacy"
                  onClick={() => this.onClick()}
                  title="Review our privacy policy"
                >
                  Privacy Policy
                </Link>
              </span>
            </div>
          </div>
        </ReactMenu>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // menu open?
  menuOpen: state.menu.open,

  // status
  status: state.context.status,

  // is there a customer logged in?
  loggedIn: state.context.customer ? true : false,

  // facilities
  facilities: state.context.facilities,

  // current path
  path: ownProps.location && ownProps.location.pathname ? ownProps.location.pathname : '',
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // menu changed
  menuChanged: (open) => {
    dispatch(open ? openMenu() : closeMenu());
  },

  // close menu
  closeMenu: () => {
    dispatch(closeMenu());
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
