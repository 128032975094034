import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import axios from 'axios';
import Cookies from 'js-cookie';
import thunk from 'redux-thunk';
import { createCookieMiddleware } from 'redux-cookie';
import axiosMiddleware from 'redux-axios-middleware';

import { axiosOptions } from './Middleware';
import rootReducer from './reducers';

// persist some of the local state across refreshes
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['context'],
  transforms: [
    createTransform(
      // we actually want to blacklist a thing or two on the context
      (inboundState, key) => {
        // strip out the third party cookie flag
        const { thirdPartyCookiesBlocked, ...stripped } = inboundState;
        return stripped;
      },

      // leave rehydrated state alone
      (outboundState, key) => {
        return outboundState;
      },

      // only call this on the context
      { whitelist: ['context'] },
    ),
  ],
};

// axios client
const client = axios.create({
  baseURL: process.env.REACT_APP_HUB,
  responseType: 'json',
  auth: {
    username: 'web-consumer',
    password: '2*9y$@ex#Rl3',
  },
});

const history = (history) => {
  // create a persist reducer that wraps the root reducer
  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  // prepare to compose enhancers
  const composeEnhancers =
    process.env.REACT_APP_ENV === 'prod' || !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? compose
      : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        }) || compose;

  // create the store with the middleware enhancers
  const store = createStore(
    connectRouter(history)(persistedReducer),
    composeEnhancers(
      applyMiddleware(
        thunk,
        routerMiddleware(history),
        createCookieMiddleware(Cookies),
        axiosMiddleware(client, axiosOptions),
      ),
    ),
  );

  // create the associated persistor
  const persistor = persistStore(store);

  // return both
  return { store, persistor };
};
export default history;
