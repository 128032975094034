import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clearFieldError, isTouchDevice } from './utility';

// field enhanced with custom behavior
export const renderEnhancedField = ({
  input,
  id = null,
  label,
  type,
  placeholder,
  meta: { _, form, touched, error },
  children,
  disabled = false,
  required = false,
  pattern,
  min,
  max,
  minLength,
  maxLength,
  labelClassName = '',
  className = '',
  tooltip = null,
  autoComplete = null,
  validate = null,
}) => (
  <div>
    {/* field label */}
    {label != null && label.length > 0 && (
      <label htmlFor={input.name} className={labelClassName}>
        {label}
      </label>
    )}

    {/* tooltip group */}
    <div className="input-group">
      {/* select */}
      {type === 'select' && (
        <select
          {...input}
          id={id ? id : input.name}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          className={className}
          data-html="true"
          title={tooltip}
          autoComplete={autoComplete}
          validate={validate}
          onFocus={() => {
            // call any passed in method
            if (input.onFocus) {
              input.onFocus();
            }

            // clear any field errors
            clearFieldError(form, id ? id : input.name);
          }}
        >
          {children &&
            Array.isArray(children) &&
            children.map(function (child) {
              return child;
            })}
          {children && !Array.isArray(children) && children}
        </select>
      )}

      {/* textarea */}
      {type === 'textarea' && (
        <div>
          <textarea
            {...input}
            id={id ? id : input.name}
            disabled={disabled}
            required={required}
            placeholder={placeholder}
            className={className}
            data-html="true"
            title={tooltip}
            autoComplete={autoComplete}
            validate={validate}
            onFocus={() => {
              // call any passed in method
              if (input.onFocus) {
                input.onFocus();
              }

              // clear any field errors
              clearFieldError(form, id ? id : input.name);
            }}
          >
            {/* TODO: Using ...input above causes children to not render; I don't know */}
            {/*       why, but we don't need children right now, so I'm ignoring.      */}
            {children}
          </textarea>
        </div>
      )}

      {/* input */}
      {type !== 'select' && type !== 'textarea' && (
        <input
          {...input}
          id={id ? id : input.name}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          type={type}
          className={className}
          pattern={pattern}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          data-html="true"
          title={tooltip}
          autoComplete={autoComplete}
          validate={validate}
          onFocus={() => {
            // call any passed in method
            if (input.onFocus) {
              input.onFocus();
            }

            // clear any field errors
            clearFieldError(form, id ? id : input.name);
          }}
        />
      )}
      {type !== 'select' && type !== 'textarea' && children}

      {/* tooltip (for touch devices) */}
      {tooltip && isTouchDevice() && (
        <div className="input-group-append">
          <span className="input-group-text">
            <div className="png-tooltip">
              <FontAwesomeIcon icon="question" />
              <span className="png-tooltip-text">{tooltip}</span>
            </div>
          </span>
        </div>
      )}
    </div>

    {/* field error */}
    {(touched || disabled) && error && <span className="png-field-error">{error}</span>}
  </div>
);
